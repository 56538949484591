import React, { useEffect, useState } from 'react';
import { Charts, DateValue, useGetTickerChartQuery, ValuePackage } from '../../api/ticker/chartsApi';
import { Chart } from 'primereact/chart';
import 'chartjs-adapter-date-fns';
import getLogger from '../../utilities/Logger';
import { Skeleton } from 'primereact/skeleton';

const log = getLogger('TickerChart');
log.setLevel('debug');

export interface TickerChartProps {
    tickerId: string;
}

export const TickerChart = (props: TickerChartProps) => {
    const { data, isLoading } = useGetTickerChartQuery({ tickerId: props.tickerId, file: Charts.OneYear });
    const { data: sma200Data } = useGetTickerChartQuery({ tickerId: props.tickerId, file: Charts.Sma200 });
    const [chartData, setChartData] = useState<any>();
    const [chartOptions, setChartOptions] = useState<any>();

    useEffect(() => {
        if (data?.vs) {
            let min: Date | undefined;
            let max: Date | undefined;

            const chartData = (ds: ValuePackage | undefined): { x: Date; y: number }[] => {
                if (!ds?.vs) return [];

                return ds.vs.map((p) => {
                    const xDate = new Date(p.td || '');
                    if (!min || xDate < min) {
                        min = xDate;
                    }
                    if (!max || xDate > max) {
                        max = xDate;
                    }
                    return {
                        x: xDate,
                        y: p.v || 0
                    };
                });
            };

            const prices = chartData(data);
            const sma200 = chartData(sma200Data);
            const datasets = [
                {
                    label: 'Price',
                    data: prices,
                    lineTension: 0.1,
                    borderColor: '#69707a'
                }
            ];

            if (sma200 && sma200.length > 0) {
                datasets.push({
                    label: 'SMA 200',
                    data: sma200,
                    lineTension: 1,
                    borderColor: '#3370AA'
                });
            }

            setChartData({
                labels: [prices.map((dp) => dp.x)],
                datasets
            });
            setChartOptions({
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    point: {
                        radius: 0
                    }
                },
                scales: {
                    x: {
                        type: 'time',
                        min: min,
                        max: max,
                        time: {
                            unit: 'month',
                            tooltipFormat: 'dd MMM yyyy',
                            displayFormats: {
                                month: 'MMM yyyy'
                            }
                        },
                        title: {
                            display: false,
                            text: 'Date'
                        }
                    },
                    y: {
                        title: {
                            display: false,
                            text: 'value'
                        }
                    }
                }
            });
        }
    }, [data, sma200Data]);

    return (
        <>
            {isLoading && <Skeleton width="100%" height="400px" />}
            {!isLoading && chartData && chartOptions && <Chart type="line" data={chartData} options={chartOptions} height={'400px'} />}
        </>
    );
};
