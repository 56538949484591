import { AccountInfo } from '@azure/msal-common';

const roleClaim: string = 'extension_OphirRoles';

export function isAdminAccount(account?: AccountInfo | null) {
    if (account && account.idTokenClaims && account.idTokenClaims.hasOwnProperty(roleClaim)) {
        const claims = account.idTokenClaims as any;
        return claims[roleClaim] === 1024;
    }
    return false;
}
