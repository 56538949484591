import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import { useNavigate } from 'react-router-dom';

import getLogger from '../utilities/Logger';
import { NavigateTo } from '../utilities/NavigateTo';
const log = getLogger('Dashcrumb');
log.setLevel('debug');

export const Dashcrumb = (props: { items: MenuItem[] }) => {
    const navigate = useNavigate();
    const home = { icon: 'pi pi-home', url: NavigateTo.dashboard };
    props.items.forEach((i) => {
        if (i.url && !i.command) {
            if (i.url?.length > 0 && i.url[0] !== '#') {
                const url = i.url;
                i.url = '#' + i.url;
                i.command = (e) => {
                    log.debug('Navigating to ' + url);
                    navigate(url);
                    e.originalEvent.preventDefault();
                };
            }
        }
    });
    return <BreadCrumb home={home} model={props.items} />;
};
