import React from 'react';
import { BadgeOperator } from '../../api/Ophir';
import * as data from '../../utilities/BadgesData';
import { BadgeSelected, printBadgeSelected } from '../../utilities/BadgeConversion';
import { selectSelectedBadges, setSelectedBadges } from './badgeSelectorSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TreeBadgeSelector } from './TreeBadgeSelector';
import { BadgeFormList } from './BadgeFormList';
import { EventType, logEvent } from '../../utilities/Analytics';
import { NavigateTo } from '../../utilities/NavigateTo';

export const BadgeSelector = (props: { mode: 'tree' | 'list' }) => {
    const selectedBadges = useSelector(selectSelectedBadges);

    const dispatch = useDispatch();

    function logClick(value: string) {
        logEvent(NavigateTo.screenerSearch, value, EventType.Click);
    }

    return (
        <>
            {props.mode === 'tree' && (
                <TreeBadgeSelector
                    values={data.allNodes()}
                    selected={selectedBadges.nodes}
                    contentClassName="si-custom-scroll si-badgeselector-treepanel"
                    onChange={(nodes: BadgeSelected) => {
                        logClick('Tree Mode - Badges: ' + printBadgeSelected(nodes));

                        dispatch(
                            setSelectedBadges({
                                operator: BadgeOperator.And,
                                nodes: nodes
                            })
                        );
                    }}
                />
            )}
            {props.mode !== 'tree' && (
                <BadgeFormList
                    values={data.allNodes()}
                    selected={selectedBadges.nodes}
                    onChange={(nodes: BadgeSelected) => {
                        logClick('List Mode - Badges: ' + printBadgeSelected(nodes));
                        dispatch(
                            setSelectedBadges({
                                operator: BadgeOperator.And,
                                nodes: nodes
                            })
                        );
                    }}
                />
            )}
        </>
    );
};
