import React from 'react';
import { Button } from 'primereact/button';
import { UserSearchModel } from '../../api/Ophir';
import { confirmDialog } from 'primereact/confirmdialog';
import { useIsAdmin } from '../../utilities/useIsAdmin';

export interface SavedSearchToolbarProps {
    selected?: UserSearchModel;
    visible: boolean;

    onCreate?: (current?: UserSearchModel) => void;
    onSave?: (current: UserSearchModel) => void;
    onEdit: (current: UserSearchModel) => void;
    onAdminEdit?: (current: UserSearchModel) => void;
    onDelete: (current: UserSearchModel) => void;
}

export const SavedSearchToolbar = (props: SavedSearchToolbarProps) => {
    let { selected } = props;
    const isAdmin = useIsAdmin();

    let onDeleteConfirm = (selected: UserSearchModel) => {
        let accept = () => props.onDelete(selected);
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept
        });
    };

    return (
        <>
            {(!selected || !selected.id) && props.onCreate && (
                <Button
                    icon="pi pi-star"
                    className="mr-2 p-button-sm p-button-rounded"
                    hidden={!props.visible}
                    label="Save"
                    onClick={() => props.onCreate && props.onCreate(props.selected)}
                />
            )}
            {selected && selected.id && (
                <>
                    {props.onSave && (
                        <Button
                            icon="pi pi-save"
                            className="mr-2 p-button-sm"
                            hidden={!props.visible}
                            onClick={() => props.selected && props.onSave && props.onSave(props.selected)}
                        />
                    )}
                    <Button
                        icon="pi pi-pencil"
                        className="mr-2 p-button-sm"
                        hidden={!props.visible}
                        onClick={() => props.selected && props.onEdit(props.selected)}
                    />
                    {isAdmin && props.onAdminEdit && (
                        <Button
                            icon="pi pi-qrcode"
                            className="mr-2 p-button-sm"
                            hidden={!props.visible}
                            onClick={() => props.selected && props.onAdminEdit && props.onAdminEdit(props.selected)}
                        />
                    )}
                    <Button
                        icon="pi pi-trash"
                        className="mr-2 p-button-sm"
                        hidden={!props.visible}
                        onClick={() => props.selected && onDeleteConfirm(props.selected)}
                    />
                </>
            )}
        </>
    );
};
