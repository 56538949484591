import React, { useEffect } from 'react';
import App from './App';
import { useLocation } from 'react-router-dom';
import { EventType, logEvent } from './utilities/Analytics';
import { NavigateTo } from './utilities/NavigateTo';
import { hasCustomAnalytics } from './App.route';

export const ctx = React.createContext();

const AppWrapper = (props) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!hasCustomAnalytics(location.pathname)) {
            logEvent(location.pathname, location.pathname, EventType.Navigation);
        }
    }, [location]);

    return <App setColorScheme={() => {}} />;
};

export default AppWrapper;
