import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ApiProvider } from './api/ApiProvider';
import { msalConfig } from './api/ApiConfig';
import { Provider } from 'react-redux';
import store from './store/store';
import { hookMsalToStore } from './auth/authStore';

const msalInstance = new PublicClientApplication(msalConfig);
hookMsalToStore(msalInstance, store.dispatch);

ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <Provider store={store}>
            <ApiProvider>
                <HashRouter>
                    <AppWrapper></AppWrapper>
                </HashRouter>
            </ApiProvider>
        </Provider>
    </MsalProvider>,

    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
