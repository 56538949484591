import React, { useState } from 'react';
import { CachePolicies, useFetch } from "use-http";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

export const TickerDailySyncButton = () => {
    const [confirmDailyDialog, showConfirmDailySync] = useState(false);

    const { post, loading } = useFetch('management/exchange', {
        cachePolicy: CachePolicies.NO_CACHE
    });

    const onSync = async () => {
        console.log('syncing...');
        await post('full-sync');
    }

    return (
        <React.Fragment>
            <ConfirmDialog
                visible={confirmDailyDialog}
                style={{ width: '450px' }} header="Confirm"
                message="Are you sure you would like to start the daily sync process?"
                accept={onSync}
                reject={() => showConfirmDailySync(false)}
                onHide={() => showConfirmDailySync(false)} />
            <Button label="Trigger Daily Sync" icon="pi pi-refresh" className="p-button-danger p-mb-2" disabled={loading} onClick={() => showConfirmDailySync(true)} />

        </React.Fragment>
    );
}