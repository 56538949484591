import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiConfig } from '../api/ApiConfig';
import { AppDispatch } from '../store/store';
import getLogger from '../utilities/Logger';
import {AccountInfo} from "@azure/msal-common/dist/account/AccountInfo";

let log = getLogger('authStore');

export interface AuthModel {
    userId: string;
    displayName: string;
    accessToken: string;
    account?: AccountInfo | null;
}

const initialState: AuthModel = {
    displayName: '',
    userId: '',
    accessToken: ''
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action: PayloadAction<AuthModel>) {
            return action.payload;
        },
        logout(state) {
            return initialState;
        },
        setAccessToken(state, action: PayloadAction<string>) {
            state.accessToken = action.payload;
        }
    }
});

export default authSlice;

let msal: PublicClientApplication | null = null;

export const getAccessToken = async (account?: AccountInfo | null): Promise<string | null> => {
    if (!msal) return null;
    try {
        let tokenResponse = await msal.acquireTokenSilent({
            scopes: apiConfig.scopes,
        });
        if (account) tokenResponse.account = account;
        if (tokenResponse.accessToken) {
            return tokenResponse.accessToken;
        }
        log.warn('no access token?');
    } catch (e) {
        // do nothing
        log.error('failed to get the token', e);
    }
    return null;
};

export const hookMsalToStore = async (msalInstance: PublicClientApplication, dispatch: AppDispatch) => {
    msal = msalInstance;
    msalInstance.addEventCallback((c) => {
        log.debug('auth event', c);
        if (c.eventType === 'msal:loginSuccess') {
            let payload: AuthenticationResult = c.payload as AuthenticationResult;
            let id = payload.idTokenClaims as any;
            dispatch(
                authSlice.actions.login({
                    accessToken: payload.accessToken,
                    displayName: id.name,
                    userId: id.sub,
                    account: payload?.account
                })
            );
        } else if (c.eventType === 'msal:acquireTokenSuccess') {
            let payload: AuthenticationResult = c.payload as AuthenticationResult;
            dispatch(authSlice.actions.setAccessToken(payload.accessToken));
        }
    });
};
