import { createSelector, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { BadgeOperator, TickerSearchRequestModel } from '../../api/Ophir';
import { RootState } from '../../store/store';
import { asSingleUIGroup, badgesToSearch, BadgeUIGroup, emptyNode, searchToBadges, splitSingleUIGroup } from '../../utilities/BadgeConversion';
import { isEmpty } from '../../utilities/Helpers';

interface BadgeSelectorState {
    selectedBadges: BadgeUIGroup;
    selectedIndustries: BadgeUIGroup;
    selectedSectors: BadgeUIGroup;
    selectedIndicators: BadgeUIGroup;
}

const initialState = {
    selectedBadges: emptyNode(BadgeOperator.Or),
    selectedIndustries: emptyNode(BadgeOperator.Or),
    selectedSectors: emptyNode(BadgeOperator.Or),
    selectedIndicators: emptyNode(BadgeOperator.Or)
} as BadgeSelectorState;

const badgeSelectorSlice = createSlice({
    name: 'badgeSelector',
    initialState,
    reducers: {
        setSearchRequest(state, action: PayloadAction<TickerSearchRequestModel>) {
            // converts the request to a readable
            const groups = searchToBadges(action.payload);

            if (groups) {
                state.selectedIndicators = groups.selectedIndicators;
                state.selectedSectors = groups.selectedSectors;
                state.selectedIndustries = groups.selectedIndustries;
                state.selectedBadges = asSingleUIGroup(groups);
            }
        },
        setSelectedBadges(state, action: PayloadAction<BadgeUIGroup>) {
            console.log('set selected', action.payload);
            state.selectedBadges = action.payload;

            const groups = splitSingleUIGroup(action.payload);
            if (groups) {
                state.selectedIndicators = groups.selectedIndicators;
                state.selectedSectors = groups.selectedSectors;
                state.selectedIndustries = groups.selectedIndustries;
            }
        }
    }
});

export const thunkRemoveAllSelectedBadges = (): ThunkAction<void, RootState, unknown, ReturnType<typeof setSelectedBadges>> => async (dispatch) => {
    dispatch(setSelectedBadges(emptyNode(BadgeOperator.And)));
};

export const thunkRemoveSelectedBadge =
    (badge: string): ThunkAction<void, RootState, unknown, ReturnType<typeof setSelectedBadges>> =>
    async (dispatch, getState) => {
        const state = getState();
        const currentBadges = state.badgeSelector.selectedBadges;
        const newCurrentBadges = {
            operator: currentBadges.operator,
            nodes: { ...currentBadges.nodes }
        };
        delete newCurrentBadges.nodes[badge];
        dispatch(setSelectedBadges(newCurrentBadges));
    };

export const selectSelectedBadges = (state: RootState) => state.badgeSelector.selectedBadges;
export const selectSelectedIndustries = (state: RootState) => state.badgeSelector.selectedIndustries;
export const selectSelectedSectors = (state: RootState) => state.badgeSelector.selectedSectors;
export const selectSelectedIndicators = (state: RootState) => state.badgeSelector.selectedIndicators;

export const selectSelectedSearchRequest = createSelector(
    [selectSelectedIndustries, selectSelectedSectors, selectSelectedIndicators],
    (industries, sectors, indicators) => {
        const joined: BadgeUIGroup = {
            operator: BadgeOperator.Or,
            nodes: { ...sectors.nodes, ...industries.nodes }
        };
        return badgesToSearch([joined, indicators]);
    }
);

export const selectHasAnySelectedBadge = createSelector([selectSelectedBadges], (badges) => badges && badges.nodes && !isEmpty(badges.nodes));

export const { setSearchRequest, setSelectedBadges } = badgeSelectorSlice.actions;

export default badgeSelectorSlice;
