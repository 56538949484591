import React from 'react';
import { Card } from 'primereact/card';
import { SearchTemplateList } from '../../components/SearchTemplates/SearchTemplateList';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { NavigateTo } from '../../utilities/NavigateTo';

export const WelcomeDashboard = () => {
    const navigate = useNavigate();
    return (
        <>
            <Card>
                <div
                    style={{
                        alignContent: 'center',
                        textAlign: 'center'
                    }}>
                    <h2>Welcome to Share Impulse's Technical Stock Screener.</h2>
                    <hr />
                    <h3>Let's get started</h3>
                    <br />
                    <div className="px-0 sm:px-5 md:px-8 lg:px-12">
                        <h3>Choose a template</h3>
                        <SearchTemplateList
                            selectText="View"
                            showDescription={true}
                            displayFlag={1}
                            showStats={false}
                            showCreateNew={true}
                            responsiveClasses="col-12 sm:col-6 "
                        />
                        <Button label={'View more'} className="p-button-outlined p-button-primary" onClick={() => navigate(NavigateTo.templates)} />
                    </div>
                </div>
            </Card>
        </>
    );
};
