import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';

const AppFooter = () => {
    return (
        <div className="layout-footer">
            <div className="grid">
                <div className="col-12">
                    <div className="footer-bottom">
                        <ConfirmDialog />
                        <h4>Share Impulse</h4>
                        <h6>Copyright &copy; Share Impulse</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppFooter;
