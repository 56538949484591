import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl, cdnUrl } from './ApiConfig';
import getLogger from '../utilities/Logger';
import { getAccessToken } from '../auth/authStore';
import { RootState } from '../store/store';
import { savedSearchStatsTag, savedSearchTag } from './OphirTags';

let log = getLogger('ophirApi');

// non authenticated api endpoints
const api = createApi({
    reducerPath: 'ophirApi',
    baseQuery: fetchBaseQuery({
        baseUrl: apiUrl
    }),
    tagTypes: [savedSearchTag, savedSearchStatsTag],
    endpoints: () => ({}) // all injected in separate files to keep things tidy :)
});

export const cdnApi = createApi({
    reducerPath: 'cdn',
    baseQuery: fetchBaseQuery({
        baseUrl: cdnUrl
    }),
    tagTypes: [],
    endpoints: () => ({}) // all injected in separate files to keep things tidy :)
});

// authenticated api
export const authApi = createApi({
    reducerPath: 'ophirAuthApi',
    baseQuery: fetchBaseQuery({
        baseUrl: apiUrl,
        prepareHeaders: async (headers, something) => {
            const state: RootState = something.getState() as RootState;
            log.debug('preparing headers ', state.auth.account);

            async function setupHeader(): Promise<boolean> {
                const token = await getAccessToken(state.auth.account);
                if (token) {
                    headers.set('authorization', `Bearer ${token}`);
                    return true;
                }
                return false;
            }

            let retry = true;
            let attempt = 0;
            while (retry && attempt < 3) {
                attempt += 1;
                retry = !(await setupHeader());
                if (retry) {
                    log.debug('waiting a second... ' + attempt);
                    await new Promise((r) => setTimeout(r, 1000));
                }
            }

            if (retry) log.error('could not set up a token, request likely to fail');

            return headers;
        }
    }),
    tagTypes: [savedSearchTag, savedSearchStatsTag],
    endpoints: () => ({}) // all injected in separate files to keep things tidy :)
});
log.debug('auth api created');

export default api;
