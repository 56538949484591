import React, { useEffect, useRef, useState } from 'react';
import { UserSearchModel } from '../../api/Ophir';
import { TickerAdvSearchGrid } from '../../components/TickerGrid/TickerAdvSearchGrid';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { BadgeSelector as BadgeSelectorV2 } from '../../components/BadgeSelector/BadgeSelector';
import { Panel } from 'primereact/panel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fromUrl, NavigateTo } from '../../utilities/NavigateTo';
import { Divider } from 'primereact/divider';
import getLogger from '../../utilities/Logger';
import { SavedSearchTitleManager } from '../../components/SavedSearches/SavedSearchTitleManager';
import { MenuItem } from 'primereact/menuitem';
import { Dashcrumb } from '../../components/Dashcrumb';
import { useGetSavedSearchQuery } from '../../api/user/savedSearchApi';
import { useIsAuthenticated } from '@azure/msal-react';
import { Messages } from 'primereact/messages';
import { SignInButton } from '../../auth/SignInButton';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { cSavedSearches, cScreener, cSearch } from '../../utilities/Crumbs';
import { useSelector } from 'react-redux';
import {
    selectHasAnySelectedBadge,
    selectSelectedSearchRequest,
    setSearchRequest,
    thunkRemoveAllSelectedBadges,
    thunkRemoveSelectedBadge
} from '../../components/BadgeSelector/badgeSelectorSlice';
import { BadgeDisplay } from '../../components/BadgeSelector/BadgeDisplay';
import { useLocalStorage } from '../../utilities/useLocalStorage';
import { HelpBox } from '../../components/assist/HelpBox';
import { defaultSearchRequest } from '../../utilities/BadgeConversion';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { EventType, logEvent } from '../../utilities/Analytics';
import { useAppDispatch } from '../../store/store';

let log = getLogger('SavedSearchForm');
log.setLevel('debug');

export const cView = {
    label: 'View'
};

export const TickerSearchPage = () => {
    const message = useRef<Messages>(null);
    const [helpState, setHelpState] = useLocalStorage('helpScreenerPage', {
        resultsDismissed: false
    });
    const { content: searchContent, id: ssId } = useParams();
    const isAuthenticated = useIsAuthenticated();

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const [hideFilterPanel, setHideFilterPanel] = useState<boolean>(true);
    const [savedSearch, setSavedSearch] = useState<UserSearchModel | undefined>(undefined);
    const [notSignedIn, setNotSignedIn] = useState(false);

    const currentSelectedSearchRequest = useSelector(selectSelectedSearchRequest);
    const hasAnyFilter = useSelector(selectHasAnySelectedBadge);
    const [badgeSelectorMode, setBadgeSelectorMode] = useState<'tree' | 'list'>('tree');

    const { data } = useGetSavedSearchQuery(Number(ssId) ?? skipToken, {
        skip: !isAuthenticated || !Number(ssId)
    });

    const [crumbs, setCrumbs] = useState<MenuItem[]>([cScreener]);

    useEffect(() => {
        if (data) {
            setSavedSearch(data);
        }
    }, [data]);

    useEffect(() => {
        if (location.pathname.includes('screener/search') && searchContent && location.pathname !== '/screener/search') {
            log.debug('removing url', location);
            // TODO https://reactrouter.com/docs/en/v6/examples/search-params
            window.history.replaceState(null, '', '#/screener/search'); // this has been already set to the state
        }
        if (location.pathname.includes('/search/') || location.pathname.includes('/share/')) {
            setCrumbs([cScreener, cSearch]);
        }
        if (location.pathname.includes('/new/') && searchContent) {
            const newFromUrl = fromUrl<UserSearchModel>(searchContent);
            log.debug('loading title and content ', newFromUrl);
            if (newFromUrl && newFromUrl.displayName && newFromUrl.content) {
                setSavedSearch({
                    displayName: newFromUrl.displayName
                });
                logEvent(location.pathname, 'New Search from Template - ' + newFromUrl.displayName, EventType.Navigation);
                setCrumbs([cScreener, cSearch]);
                window.history.replaceState(null, '', '#/screener/search'); // this has been already set to the state
                dispatch(setSearchRequest(newFromUrl.content));
            }
        }
        if (location.pathname.endsWith('/blank')) {
            logEvent(location.pathname, 'New Blank Search', EventType.Navigation);
            setSavedSearch({
                displayName: ''
            });
            setCrumbs([cScreener, cSearch]);
            window.history.replaceState(null, '', '#/screener/search'); // this has been already set to the state
            dispatch(setSearchRequest({ ...defaultSearchRequest }));
        }
        if (location.pathname.includes('/view/')) {
            logEvent(location.pathname, 'View Saved Screener', EventType.Navigation);
            setCrumbs([cScreener, cSavedSearches, cView]);
        }
    }, [location, searchContent, dispatch]);

    useEffect(() => {
        if (ssId && !isAuthenticated) {
            setNotSignedIn(true);
        } else {
            setNotSignedIn(false);
        }
    }, [ssId, isAuthenticated]);

    useEffect(() => {
        if (message?.current) {
            if (notSignedIn) {
                message.current.clear();
                message.current.show({
                    sticky: true,
                    severity: 'error',
                    detail: (
                        <div className="ml-2 flex align-items-center justify-content-between ">
                            <p>You need to sign in to view a Saved Screen.</p>
                            <p className="ml-5 ">
                                <SignInButton
                                    analytics={{
                                        href: NavigateTo._savedSearch,
                                        title: 'Login to view Saved Screener',
                                        type: EventType.Click
                                    }}
                                />
                            </p>
                        </div>
                    )
                });
            } else {
                message.current.clear();
            }
        }
    }, [message, notSignedIn]);

    useEffect(() => {
        if (savedSearch && savedSearch.content) {
            // means that the data is now loaded or has changed
            dispatch(setSearchRequest(savedSearch.content));
        }
    }, [savedSearch, dispatch]);

    function toggleBadgeMode() {
        setHideFilterPanel(true);
        if (badgeSelectorMode === 'tree') {
            logClick('Badge Selector - Changed to LIST mode');
            setBadgeSelectorMode('list');
        } else {
            logClick('Badge Selector - Changed to TREE mode');
            setBadgeSelectorMode('tree');
        }
        setHideFilterPanel(false);
    }

    function logClick(value: string) {
        logEvent(NavigateTo.screenerSearch, value, EventType.Click);
    }

    return (
        <>
            <div className="card">
                <Dashcrumb items={crumbs} />
                <h1>
                    <SavedSearchTitleManager selected={savedSearch} searchRequest={currentSelectedSearchRequest} />
                </h1>
                <hr />
                <Messages ref={message} />
                <div className="text-center">
                    {!hasAnyFilter && (
                        <div className="flex justify-content-center align-items-center">
                            <Button
                                label="Choose a Template"
                                onClick={() => {
                                    logClick('Clicked Choose Template');
                                    navigate(NavigateTo.templates);
                                }}
                            />
                            <h4 className={'m-4'}>or</h4>
                            <Button
                                onClick={() => {
                                    logClick('Adding FIRST filter');
                                    setHideFilterPanel(false);
                                }}>
                                Add a filter
                            </Button>
                        </div>
                    )}
                    {hasAnyFilter && (
                        <>
                            <h3>Selected filters</h3>
                            <BadgeDisplay
                                onRemove={(b) => {
                                    logClick('Removed filter using Badge X button: ' + b.key);
                                    dispatch(thunkRemoveSelectedBadge(b.key?.toString() || ''));
                                }}
                            />
                            <br />
                            <Button
                                icon="pi pi-times"
                                label="Remove all"
                                onClick={() => {
                                    logClick('Removed ALL badges');
                                    dispatch(thunkRemoveAllSelectedBadges());
                                }}
                                className="p-button-sm p-button-rounded p-button-danger mb-3"
                            />
                        </>
                    )}
                </div>
                {!hideFilterPanel && (
                    <Dialog
                        header={() => (
                            <div className="flex justify-content-between align-items-start">
                                <span>Filters</span>
                                <span className="text-sm ml-2 flex align-items-center">
                                    <span className="mr-2">Tree mode</span>
                                    <InputSwitch checked={badgeSelectorMode === 'tree'} onChange={toggleBadgeMode} />
                                </span>
                            </div>
                        )}
                        visible={!hideFilterPanel}
                        footer={<Button label="View" onClick={() => setHideFilterPanel(true)} />}
                        style={{ maxWidth: '600px' }}
                        onHide={() => setHideFilterPanel(true)}>
                        <BadgeSelectorV2 mode={badgeSelectorMode} />
                    </Dialog>
                )}

                {hasAnyFilter && (
                    <Divider align="center">
                        <div className="inline-flex align-items-center">
                            <>
                                <Button
                                    onClick={() => {
                                        logClick('Editing existing filters');
                                        setHideFilterPanel(false);
                                    }}>
                                    Edit Filters
                                </Button>
                            </>
                        </div>
                    </Divider>
                )}

                <Panel
                    className={classNames('col-12')}
                    header={
                        <div className="flex align-items-center">
                            <span>Results</span>
                            <Button
                                icon="pi pi-info-circle"
                                className="ml-2 p-button-rounded p-button-info "
                                onClick={() => {
                                    const toggle = !helpState.resultsDismissed;
                                    logClick('Clicked on Results HELP button - ' + (toggle ? 'Hide' : 'Show'));
                                    setHelpState({
                                        resultsDismissed: toggle
                                    });
                                }}
                            />
                        </div>
                    }>
                    {!helpState.resultsDismissed && (
                        <HelpBox
                            onClose={() => {
                                logClick('Closed the First Seen help box');
                                setHelpState({ ...helpState, resultsDismissed: true });
                            }}>
                            As you change your filters the results are updated below. <strong>First Seen</strong> column shows when the stock was
                            first seen according to your criteria.
                        </HelpBox>
                    )}

                    <TickerAdvSearchGrid header="List of stocks" searchRequest={currentSelectedSearchRequest} />
                </Panel>
            </div>
        </>
    );
};
