import React, { useMemo, useState } from 'react';
import { SearchStatsModel, UserSearchModel } from '../../api/Ophir';
import { Skeleton } from 'primereact/skeleton';
import { stringToHslColor } from '../../utilities/Helpers';

export const SavedSearchCardSkeleton = () => {
    return (
        <div className="custom-skeleton p-4">
            <Skeleton width="100%" height="150px" />
        </div>
    );
};

export interface SearchTemplateProps {
    search: UserSearchModel;
    selectText: string;
    stats?: SearchStatsModel[];
    isStatsLoading: boolean;
    onSelect: (selected: UserSearchModel) => void;
}

export const SavedSearchDashCard = (props: SearchTemplateProps) => {
    function findStat(stats?: SearchStatsModel[], hash?: string): SearchStatsModel | null {
        if (!hash) return null;
        if (!stats) return null;
        let result: SearchStatsModel | null = null;
        stats.some((s) => {
            if (s.searchHash === hash) {
                result = s;
                return true;
            }
            return false;
        });
        return result;
    }

    const [stat, setStat] = useState(findStat(props.stats, props.search.searchHash));
    useMemo(() => {
        setStat(findStat(props.stats, props.search.searchHash));
    }, [props.stats, props.search]);

    const [color] = useState(props.search.displayName ? stringToHslColor(props.search.displayName) : 'white');
    return (
        <div className={'p-link'}>
            <div
                className="card"
                style={{ borderColor: color, borderStyle: 'solid', borderWidth: '2px' }}
                onClick={() => props.onSelect(props.search)}>
                <div className="dashboard-ss-box-title">
                    <h6>{props.search.displayName}</h6>
                    <i className="pi pi-window-maximize" />
                </div>

                <div className="dashboard-ss-box">
                    <div className="inline">
                        <div className="overview-info">
                            <h6>Stocks</h6>
                            <h1>
                                {props.isStatsLoading && <i className="pi pi-spin pi-spinner" />}
                                {!props.isStatsLoading && stat && '' + stat.totalCount}
                                {!props.isStatsLoading && !stat && '-'}
                            </h1>
                        </div>
                        <i className="pi pi-chart-bar" />
                    </div>
                    <div className="inline">
                        <div className="overview-info">
                            <h6>New Stocks</h6>
                            <h1>
                                {props.isStatsLoading && <i className="pi pi-spin pi-spinner" />}
                                {!props.isStatsLoading && stat && stat.newCount}
                                {!props.isStatsLoading && !stat && '-'}
                            </h1>
                        </div>
                        <i className="pi pi-sort-alt" />
                    </div>
                </div>
            </div>
            {/* 
            <Card
                title={<>{props.search.displayName}</>}
                subTitle={props.search.description && <ReactMarkdown>{props.search.description}</ReactMarkdown>}
                key={`trends-card-${x++}`}>
                <div className="card statistics">
                    <div className="grid">
                        <div className="p-col-12 p-md-6">
                            <div className="statistic-item">
                                <div className="item-title">
                                    <span>
                                        <i className="pi pi-chart-bar"></i>
                                    </span>
                                    <h5>
                                        {props.isStatsLoading && <i className="pi pi-spin pi-spinner"></i>}
                                        {!props.isStatsLoading && stat && '' + stat.totalCount}
                                        {!props.isStatsLoading && !stat && '-'}
                                    </h5>
                                </div>
                                <h6>Stocks</h6>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="statistic-item">
                                <div className="item-title">
                                    <span>
                                        <i className="pi pi-sort-alt"></i>
                                    </span>
                                    <h5>
                                        {props.isStatsLoading && <i className="pi pi-spin pi-spinner"></i>}
                                        {!props.isStatsLoading && stat && stat.newCount}
                                        {!props.isStatsLoading && !stat && '-'}
                                    </h5>
                                </div>
                                <h6>New stocks</h6>
                            </div>
                        </div>
                    </div>
                    <Button style={{ width: '100%' }} onClick={() => props.onSelect(props.search)} className="block">
                        {props.selectText}
                    </Button>
                </div>
            </Card> */}
        </div>
    );
};
