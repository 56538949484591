import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { useFetch } from "use-http";
import { TickerMetaModel, TickerPriceModel } from '../../../api/Ophir';

export interface TickerPricesGridProp {
    header: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined,
    ticker?: TickerMetaModel
}

export const TickerPricesGrid = (props: TickerPricesGridProp) => {
    const {
        data,
        loading
     } = useFetch<TickerPriceModel[]>(`management/ticker/${props?.ticker?.tickerId}/price`, []);

     return (
        <DataTable
            value={data}
            dataKey="tradeDate"
            paginator
            rows={20}
            rowsPerPageOptions={[20, 40, 80]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} prices"
            emptyMessage="No prices found"
            loading={loading}
            header={props.header}>
            <Column field="tradeDate" header="Date" sortable></Column>
            <Column field="close" header="Close" sortable></Column>
            <Column field="open" header="Open" sortable></Column>
            <Column field="high" header="High" sortable></Column>
            <Column field="low" header="Low" sortable></Column>
            <Column field="volume" header="Volume" sortable></Column>
        </DataTable>
    );

}