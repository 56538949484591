import { DropdownChangeParams, Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useFetch } from "use-http";
import { ExchangeMetaModel, ListResponseObjectOfExchangeMetaModel } from '../api/Ophir';

export interface ExchangeDropdownProp {
    id?: string | undefined,
    value?: string | undefined,
    disabled?: boolean | undefined,
    onSelected?: ((e: DropdownChangeParams) => void) | undefined
}

export const ExchangeDropdown = (props: ExchangeDropdownProp) => {
    const [exchanges, setExchanges] = useState<ExchangeMetaModel[]>([]);
    const { data = {} } = useFetch('lookup/exchange', []);
    useEffect(() => {
        const resp = data as ListResponseObjectOfExchangeMetaModel;
        if (resp.object) {
            setExchanges(resp.object);
        }
    }, [data]);
    return (
        <Dropdown 
            id={props.id}
            value={props.value} 
            onChange={props.onSelected}
            options={exchanges} 
            optionLabel="exchange" 
            optionValue="exchange" 
            dataKey="exchange"
            disabled={props.disabled}
            placeholder="Select exchange"  />
    );

}