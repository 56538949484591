import React from 'react';
import * as data from '../../utilities/BadgesData';
import { BadgeUIGroup } from '../../utilities/BadgeConversion';
import { selectSelectedIndicators, selectSelectedIndustries, selectSelectedSectors } from './badgeSelectorSlice';
import { useSelector } from 'react-redux';
import { Chip } from 'primereact/chip';
import TreeNode from 'primereact/treenode';

interface BadgeDisplayProps {
    onRemove: (badge: TreeNode) => void;
}

export const BadgeDisplay = (props: BadgeDisplayProps) => {
    const industries = useSelector(selectSelectedIndustries);
    const sectors = useSelector(selectSelectedSectors);
    const indicators = useSelector(selectSelectedIndicators);

    function* renderBadgeGroup(badges: BadgeUIGroup, prefix?: string) {
        for (const b in badges.nodes) {
            const badge = data.findByKey(b);
            if (badge) {
                yield (
                    <Chip
                        key={badge.key}
                        className="mb-2 mr-2"
                        label={'' + (prefix ?? '') + badge.label}
                        removable={true}
                        onRemove={() => props.onRemove(badge)}
                    />
                );
            }
        }
    }

    return (
        <>
            {Array.from(renderBadgeGroup(sectors, 'Sector: '))}
            {Array.from(renderBadgeGroup(industries, 'Industry: '))}
            {Array.from(renderBadgeGroup(indicators))}
        </>
    );
};
