import React, { ReactNode } from 'react';

export function HelpBox({ onClose, children }: { onClose: () => void; children: ReactNode }) {
    return (
        <div className="p-message p-component p-message-info p-message-enter-done">
            <div className="p-message-wrapper">
                <span className="p-message-icon pi  pi-info-circle"></span>
                <div className="ml-2">{children}</div>
                <button type="button" className="p-message-close p-link" onClick={onClose}>
                    <i className="p-message-close-icon pi pi-times"></i>
                    <span className="p-ink"></span>
                </button>
            </div>
        </div>
    );
}
