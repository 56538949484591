import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../api/ApiConfig';
import { Button } from 'primereact/button';
import {AnalyticsEvent, logEvent} from '../utilities/Analytics';

export interface SignInButtonProps {
    label?: string;
    className?: string;
    onClick?: () => void;
    analytics: AnalyticsEvent;
}

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = ({ label, onClick, className, analytics }: SignInButtonProps) => {
    const { instance } = useMsal();

    const handleLogin = () => {
        logEvent(analytics);
        instance
            .loginPopup(loginRequest)
            .then((response) => {
                instance.setActiveAccount(response.account);
                if (onClick) onClick();
            })
            .catch((e) => {
                console.log(e);
                if (onClick) onClick();
            });
        // or redirect:
        // instance.loginRedirect(loginRequest).catch(e => {
        //     console.log(e);
        // });
    };

    return <Button label={label || 'Login'} className={className || ' mr-2 mb-2'} onClick={handleLogin} />;
};
