import React from 'react';
import { Card } from 'primereact/card';
import { SavedSearchManagement } from '../../components/SavedSearches/SavedSearchManagement';
import { useNavigate } from 'react-router-dom';
import { NavigateTo } from '../../utilities/NavigateTo';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';

export const SavedSearchesPage = () => {
    const navigate = useNavigate();
    return (
        <>
            <Card>
                <h1>Saved screeners</h1>
                <hr />
                <Button icon={PrimeIcons.PLUS} iconPos="left" label="Create new screener" onClick={() => navigate(NavigateTo.templates)} />

                <SavedSearchManagement />
            </Card>
        </>
    );
};
