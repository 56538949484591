import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { useFetch } from "use-http";
import { TickerMetaModel, TickerIndicatorModel } from '../../../api/Ophir';

export interface TickerIndicatorsGridProp {
    header: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined,
    ticker?: TickerMetaModel
}

export const TickerIndicatorsGrid = (props: TickerIndicatorsGridProp) => {
    const {
        data,
        loading
     } = useFetch<TickerIndicatorModel[]>(`management/ticker/${props?.ticker?.tickerId}/indicator`, []);

     return (
        <DataTable
            value={data}
            dataKey="tradeDate"
            paginator
            rows={20}
            rowsPerPageOptions={[20, 40, 80]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} indicators"
            emptyMessage="No indicators found"
            loading={loading}
            header={props.header}>
            <Column field="tradeDate" header="Date" sortable></Column>
            <Column field="indicator" header="IndicatorId" sortable></Column>
            <Column field="doubleValue" header="Value" sortable></Column>
        </DataTable>
    );

}