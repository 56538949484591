import React, { useMemo } from 'react';
import TreeNode from 'primereact/treenode';
import { Checkbox } from 'primereact/checkbox';
import { BadgeSelected } from '../../utilities/BadgeConversion';

import getLogger from '../../utilities/Logger';
import {Badge} from "primereact/badge";

const log = getLogger('BadgeFormList');
log.setLevel('debug');

type TreeNodeWithCount = Partial<TreeNode> & { count?: number };

export interface BadgeDisplayProps {
    id?: string | undefined;
    values: TreeNodeWithCount[];
    selected: BadgeSelected;
    contentClassName?: string;
    onChange: (nodes: BadgeSelected) => void;
}

export const BadgeFormList = (props: BadgeDisplayProps) => {
    const badges = props.selected;
    const nodes = props.values;

    function count(nodes?: TreeNode[], selected?: BadgeSelected): number {
        if (!selected || !nodes || nodes.length === 0) return 0;
        let result = 0;
        nodes.forEach((n) => {
            if (selected[n.key as string]) {
                result += 1;
            }
            result += count(n.children, selected);
        });
        return result;
    }

    function countChildren(nodes?: TreeNodeWithCount[], selected?: BadgeSelected) {
        if (!nodes || nodes.length === 0) return 0;

        nodes.forEach((n) => {
            n.count = count(n.children, selected);
            countChildren(n.children, selected);
        });
    }

    // TOOD copied and pasted below. need to move to some slice and reselect
    useMemo(() => {
        countChildren(props.values, props.selected);
    }, [props.selected, props.values, countChildren]);

    function renderTreeNode(node: TreeNodeWithCount): React.ReactNode {
        return (
            <div className="d-block mt-2 pt-2">
                <h5>{node.label}  {typeof node.count=== 'number' && node.count > 0 && (<Badge key={`bs_badge_${node.key}`} value={node.count} />)}</h5>

                <div className="flex flex-wrap">
                    {node.children?.map((n) => (
                        <div className="m-2 ">
                            <Checkbox
                                key={`'ck_${n.key}`}
                                inputId={n.key as string}
                                checked={badges[n.key as string]}
                                value={n.key}
                                className="mr-2"
                                name={n.key as string}
                                onChange={(e) => {
                                    let newValue = { ...props.selected };
                                    if (e.checked) {
                                        newValue[n.key as string] = true;
                                    } else {
                                        delete newValue[n.key as string];
                                    }
                                    log.debug('new value from formlist', newValue);
                                    props.onChange(newValue);
                                }}>
                                {n.label}
                            </Checkbox>
                            <label htmlFor={n.key as string} className="" key={`label_${n.key}`}>
                                {n.label}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return <>{nodes.map((n) => n.children?.map((c) => renderTreeNode(c)))}</>;
};
