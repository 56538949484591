import React from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { NotFound } from './pages/NotFound';

import { TickersPage } from './management/ticker/TickersPage';
import { TickerSearchPage } from './pages/screener/TickerSearchPage';
import { TrendsDashboardPage } from './pages/dashboard/TrendsDashboardPage';
import { SearchTemplatesPage } from './pages/savedsearches/SearchTemplatesPage';
import { SavedSearchesPage } from './pages/savedsearches/SavedSearchesPage';
import { NavigateTo } from './utilities/NavigateTo';
import { useIsAuthenticated } from '@azure/msal-react';
import { LoginRequiredPage } from './pages/generic/LoginRequiredPage';
import { AccessDeniedPage } from './pages/generic/AccessDeniedPage';
import { useIsAdmin } from './utilities/useIsAdmin';
import { SavedSearchesAdminPage } from './pages/savedsearches/SavedSearchesAdminPage';

export function hasCustomAnalytics(pathname: string): boolean {
    if (!pathname) return false;

    if (pathname.startsWith('/screener')) {
        return true;
    }

    // noinspection RedundantIfStatementJS (explicit readability)
    if (pathname === '/') {
        return true;
    }

    return false;
}

export const contentRoutes = (
    <Routes key="routeSwitch">
        <Route path="/" element={<TrendsDashboardPage />} />
        <Route path="/login" element={<LoginRequiredPage />} />
        <Route path="/accessdenied" element={<AccessDeniedPage />} />
        {/* Screener */}
        <Route path="/screener">
            <Route path="share/:title/:content" element={<TickerSearchPage />} />
            <Route path="new/:content" element={<TickerSearchPage />} />
            <Route path="search/:content" element={<TickerSearchPage />} />
            {/* Saved Searches */}
            <Route path="view/:id" element={<TickerSearchPage />} />
            <Route path="search" element={<TickerSearchPage />} />
            <Route path="blank" element={<TickerSearchPage />} />
            <Route index element={<TickerSearchPage />} />
        </Route>
        <Route path={NavigateTo.templates} element={<RequireAuth />}>
            <Route path={''} element={<SearchTemplatesPage />} />
        </Route>
        <Route path={NavigateTo.savedSearches} element={<RequireAuth />}>
            <Route path={''} element={<SavedSearchesPage />} />
        </Route>
        <Route path={NavigateTo.savedSearchesAdmin} element={<RequireAdmin />}>
            <Route path={''} element={<SavedSearchesAdminPage />} />
        </Route>
        <Route path="/management/ticker" element={<RequireAdmin />}>
            <Route path="/management/ticker" element={<TickersPage />} />
        </Route>

        <Route path="*" element={<NotFound />} />
    </Routes>
);

function RequireAuth(): JSX.Element {
    const auth = useIsAuthenticated();
    const location = useLocation();

    if (!auth) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <Outlet />;
}

function RequireAdmin(): JSX.Element {
    const location = useLocation();
    const isAdmin = useIsAdmin();

    if (!isAdmin) {
        return <Navigate to="/accessdenied" state={{ from: location }} replace />;
    }

    return <Outlet />;
}
