import { NavigateTo } from './NavigateTo';

export const cScreener = {
    label: 'Screener',
    url: NavigateTo.screener
};

export const cSearch = {
    label: 'Search'
};

export const cSavedSearches = {
    label: 'Saved Screens',
    url: NavigateTo.savedSearches
};
