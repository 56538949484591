import React from 'react';
import { Card } from 'primereact/card';
import { SearchTemplateList } from '../../components/SearchTemplates/SearchTemplateList';
import { SavedSearchCardList } from '../../components/SavedSearches/SavedSearchCardList';
import {MarketStatsDashboard} from "./MarketStatsDashboard";

export const MainDashboard = () => {
    return (
        <>
            <div className="grid">
                <div className="col-12 sm:col-8">
                    <Card title="My screeners" className="mb-4">
                        <div className={'grid'}>
                            <SavedSearchCardList />
                        </div>
                    </Card>
                </div>
                <div className="col-12 sm:col-4">
                    <Card title="Market Overview" className="mb-4">
                        <div className={'grid'}>
                            <MarketStatsDashboard />
                        </div>
                    </Card>
                </div>
            </div>
            <Card title="Templates">
                <div className="grid px-4">
                    <SearchTemplateList selectText="View" showDescription={false} displayFlag={0} showStats={true} showCreateNew={false} />
                </div>
            </Card>
        </>
    );
};
//import { ProgressBar } from 'primereact/progressbar';
