import { useIsAuthenticated } from '@azure/msal-react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { SavedSearchAdminModel, UserSearchModel } from '../../api/Ophir';
import { useDeleteSavedSearchMutation, useGetSavedAdminSearchesQuery, useUpdateSavedSearchAdminSettingMutation } from '../../api/user/savedSearchApi';
import { SavedSearchToolbar } from './SavedSearchToolbar';
import getLogger from '../../utilities/Logger';
import { useNavigate } from 'react-router-dom';
import { NavigateTo } from '../../utilities/NavigateTo';
import { Dialog } from 'primereact/dialog';
import { SavedSearchAdminForm } from './SavedSearchAdminForm';

let log = getLogger('SavedSearchTitle');
log.setLevel('debug');

export const SavedSearchAdminManagement = () => {
    const isAuthenticated = useIsAuthenticated();
    const toast = useRef<Toast>(null);
    const navigate = useNavigate();
    const [adminEditDialog, setAdminEditDialog] = useState<boolean>(false);
    const [search, setSearch] = useState<UserSearchModel>({});
    const [isChanging, setChanging] = useState<boolean>(false);

    const [searches, setSearches] = useState<UserSearchModel[]>([]);
    const [updateSearchAdmin, { isLoading: isUpdatingAdmin }] = useUpdateSavedSearchAdminSettingMutation();

    const { data, isLoading } = useGetSavedAdminSearchesQuery(isAuthenticated ? undefined : skipToken, {
        skip: !isAuthenticated
    });

    const [deletePost] = useDeleteSavedSearchMutation();

    useEffect(() => {
        setChanging(isUpdatingAdmin);
    }, [isUpdatingAdmin]);

    useEffect(() => {
        if (data && data.object && data.object.length > 0) {
            setSearches(data.object);
        } else {
            setSearches([]);
        }
    }, [data]);

    function toolbarEdit(userSearch: UserSearchModel) {
        navigate(NavigateTo.savedSearch(userSearch));
    }

    async function toolbarDelete(userSearch: UserSearchModel) {
        let deleteResult = await deletePost(userSearch).unwrap();
        log.debug(deleteResult);
        toast?.current?.show([
            {
                severity: 'warn',
                summary: 'Screen Deleted'
            }
        ]);
    }

    async function toolbarAdminEdit(userSearch: UserSearchModel) {
        log.debug('admin edit from list', userSearch);
        if (userSearch.id) {
            setAdminEditDialog(true);
            setSearch(userSearch);
        }
    }

    async function savedSearchSaveAdminSubmit(e: SavedSearchAdminModel) {
        log.debug('submitting finally via api', e);
        try {
            const r = await updateSearchAdmin(e);
            log.debug('response object?', r);
            setAdminEditDialog(false);
        } catch (e) {
            log.error('failed');
        }
    }

    const toolbarColumn = (r: UserSearchModel) => (
        <SavedSearchToolbar selected={r} onDelete={toolbarDelete} onEdit={toolbarEdit} onAdminEdit={toolbarAdminEdit} visible={true} />
    );
    return (
        <>
            <DataTable value={searches} loading={isLoading} responsiveLayout="scroll">
                <Column field="displayName" header="Name" />
                <Column field="description" header="Description" body={(r: UserSearchModel) => <p className="overflow-hidden">{r.description}</p>} />
                <Column field="admin.templateGroupId" header="Group" />
                <Column field="admin.templateFlag" header="Flag" />
                <Column key="management" body={toolbarColumn} />
            </DataTable>
            <Dialog
                header="Admin Settings"
                visible={adminEditDialog}
                onHide={() => setAdminEditDialog(false)}
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '50vw' }}>
                <SavedSearchAdminForm
                    selected={search.admin ?? { id: search.id }}
                    isChanging={isChanging}
                    onSubmit={savedSearchSaveAdminSubmit}
                    onCancel={() => setAdminEditDialog(false)}
                />
            </Dialog>
            <Toast ref={toast} />
        </>
    );
};
