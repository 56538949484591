import React from 'react';
import { Button } from 'primereact/button';
import { useForm, Controller, FieldError } from 'react-hook-form';
import { UserSearchModel } from '../../api/Ophir';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import classNames from 'classnames';
import getLogger from '../../utilities/Logger';

let log = getLogger('SavedSearchForm');
log.setLevel('debug');

export interface SavedSearchFormProps {
    selected?: UserSearchModel;
    children: any;
    isChanging: boolean;

    onCancel: () => void;
    onSubmit: (selected: UserSearchModel) => void;
}

export const SavedSearchForm = (props: SavedSearchFormProps) => {
    const {
        control,
        formState: { errors },
        handleSubmit
    } = useForm({ defaultValues: props.selected });
    const onSubmit = (data: UserSearchModel) => {
        log.debug('submitting', data); // need to confirm what is the data here and what is in the selected

        if (!data) {
            log.error('blank data received on edit?');
            props.onCancel();
            return;
        }

        props.onSubmit(data);
    };

    const getFormErrorMessage = (name: keyof UserSearchModel) => {
        let state: FieldError | undefined = errors[name] as FieldError;
        return state && <small className="p-error">{state.message}</small>;
    };
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                <div className="field mt-2">
                    <span className="p-label">
                        <label htmlFor="displayName" className={classNames({ 'p-error': errors.displayName })}>
                            Name*
                        </label>
                        <Controller
                            name="displayName"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'You need to give it a name.' }}
                            render={({ field, fieldState }) => (
                                <InputText
                                    disabled={props.isChanging}
                                    id={field.name}
                                    {...field}
                                    autoFocus
                                    className={classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )}
                        />
                    </span>
                    {getFormErrorMessage('displayName')}
                </div>
                <div className="field  mt-2 mb-2">
                    <span className="p-label">
                        <label htmlFor="Description" className={classNames({ 'p-error': !!errors.description })}>
                            Description
                        </label>
                        <Controller
                            name="description"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: false
                            }}
                            render={({ field, fieldState }) => (
                                <InputTextarea
                                    disabled={props.isChanging}
                                    id={field.name}
                                    {...field}
                                    className={classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )}
                        />
                    </span>
                    {getFormErrorMessage('description')}
                </div>
                {props.children}
                <div className="flex mt-5">
                    <Button disabled={props.isChanging} type="button" label="Cancel" className="mr-2 p-button-secondary" onClick={props.onCancel} />
                    <Button disabled={props.isChanging} type="submit" label="Save" className="mr-2" />
                </div>
            </form>
        </>
    );
};
