import TreeNode from 'primereact/treenode';
import { Tree, TreeNodeTemplateOptions, TreeSelectionParams } from 'primereact/tree';
import React, { useMemo } from 'react';
import { BadgeSelected } from '../../utilities/BadgeConversion';
import getLogger from '../../utilities/Logger';
import { Badge } from 'primereact/badge';

const log = getLogger('TreeBadgeSelector');
log.setLevel('debug');

type TreeNodeWithCount = Partial<TreeNode> & { count?: number }; 

export interface TreeBadgeSelectorProps {
    id?: string | undefined;
    values: TreeNodeWithCount[];
    selected: BadgeSelected;
    contentClassName?: string;
    onChange: (nodes: BadgeSelected) => void;
}

export const TreeBadgeSelector = (props: TreeBadgeSelectorProps) => {
    function count(nodes?: TreeNode[], selected?: BadgeSelected): number {
        if (!selected || !nodes || nodes.length === 0) return 0;
        let result = 0;
        nodes.forEach((n) => {
            if (selected[n.key as string]) {
                result += 1;
            }
            result += count(n.children, selected);
        });
        return result;
    }

    function countChildren(nodes?: TreeNodeWithCount[], selected?: BadgeSelected) {
        if (!nodes || nodes.length === 0) return 0;
        
        nodes.forEach((n) => {
            n.count = count(n.children, selected);
            countChildren(n.children, selected);
        });
    }
    
    // TOOD copied and pasted below. need to move to some slice and reselect
    useMemo(() => {
        countChildren(props.values, props.selected)
    }, [props.selected, props.values, countChildren]);

    
    return (
        <Tree
            selectionKeys={props.selected}
            value={props.values}
            filter={true}
            filterBy={'label'}
            metaKeySelection={false}
            contentClassName={props.contentClassName}
            selectionMode="multiple"
            nodeTemplate={nodeTemplate}
            onSelectionChange={selectionChanged}
        />
    );

    function selectionChanged(e: TreeSelectionParams) {
        log.debug('selection changed', e);
        if (e.originalEvent.type === 'click') {
            if (typeof e.value === 'string') {
                let evt: BadgeSelected = {};
                evt[e.value as string] = true;
                props.onChange(evt);
            } else {
                log.debug('value', e.value);
                props.onChange(e.value as BadgeSelected);
            }
        }
    }
    
    function templateClick(isHeader: boolean, event: React.SyntheticEvent, options: TreeNodeTemplateOptions) {
        if (isHeader) {
            options.onTogglerClick(event);
        }
    }
    function nodeTemplate(e: TreeNodeWithCount, options: TreeNodeTemplateOptions) {
        const isHeader = (e.children && e.children.length > 0 && typeof e.count === 'number' && e.count > 0) || false;
        return <span style={{width: '100%'}} onClick={(e) => templateClick(isHeader, e, options)}>
                    {e.label} &nbsp; {isHeader && (
            <Badge key={`bs_badge_${e.key}`} value={e.count}/>)}
                </span>;
    }


};
