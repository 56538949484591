import React, { useMemo } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { WelcomeDashboard } from './WelcomeDashboard';
import { useGetSavedSearchesQuery } from '../../api/user/savedSearchApi';
import { MainDashboard } from './MainDashboard';
import { SavedSearchCardSkeleton } from '../../components/SavedSearches/SavedSearchDashCard';
import getLogger from '../../utilities/Logger';
import { skipToken } from '@reduxjs/toolkit/query';
import { useLocation } from 'react-router-dom';
import { EventType, logEvent } from '../../utilities/Analytics';

let log = getLogger('Dashboard');

export const TrendsDashboardPage = () => {
    const isAuthenticated = useIsAuthenticated();
    const { data, isLoading } = useGetSavedSearchesQuery(isAuthenticated ? undefined : skipToken, { skip: !isAuthenticated });
    const location = useLocation();
    useMemo(() => {
        if (location.pathname === '/') {
            if (isAuthenticated) {
                logEvent(location.pathname, "Home Dashboard", EventType.Navigation);
            } else {
                logEvent(location.pathname, "Welcome Page", EventType.Navigation);
            }
        }
    }, [location, isAuthenticated]);

    return (
        <>
            <UnauthenticatedTemplate>
                <WelcomeDashboard />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                {isLoading && <SavedSearchCardSkeleton />}
                {!isLoading && data && data.object && data.object.length === 0 && <WelcomeDashboard />}
                {!isLoading && data && data.object && data.object.length > 0 && <MainDashboard />}
            </AuthenticatedTemplate>
        </>
    );
};
