import TreeNode from 'primereact/treenode';
import { TreeSelectSelectionKeysType } from 'primereact/treeselect';
import { BadgeConditionModel, BadgeGroupConditionModel, BadgeOperator, BadgeValueConditionModel, TickerSearchRequestModel } from '../api/Ophir';
import { IndustryPrefix, SectorPrefix } from './BadgesData';
import { isEmpty } from './Helpers';
import getLogger from './Logger';
const log = getLogger('BadgeConversion');

export const defaultSearchRequest: TickerSearchRequestModel = {
    badges: {
        operator: BadgeOperator.And,
        badges: []
    }
};

export interface SelectedUIBadges {
    selectedIndustries: BadgeUIGroup;
    selectedSectors: BadgeUIGroup;
    selectedIndicators: BadgeUIGroup;
}

export interface BadgeUIGroup {
    operator: BadgeOperator;
    nodes: BadgeSelected;
}

export interface SelectedItems {
    [key: string]: { checked: boolean };
}

export interface BadgeSelected {
    [key: string]: boolean;
}

export function emptyNode(operator: BadgeOperator): BadgeUIGroup {
    return {
        operator: operator,
        nodes: {}
    };
}

export function emptySelectedUIBadges() {
    return {
        selectedIndicators: emptyNode(BadgeOperator.And),
        selectedSectors: emptyNode(BadgeOperator.Or),
        selectedIndustries: emptyNode(BadgeOperator.Or)
    };
}

export const sameBadges = (a: BadgeSelected, b: BadgeSelected): Boolean => {
    if (a === b) return true;
    if (a === null || b === null) return false;
    if (a.length !== b.length) return false;

    for (const p in a) {
        if (!b[p]) return false;
    }
    for (const p in b) {
        if (!a[p]) return false;
    }

    return true;
};

export const hasBadge = (badges: BadgeSelected | undefined | null, exp: (v: string) => boolean): boolean => {
    if (!badges) return false;

    for (const b in badges) {
        if (badges[b] && exp(b)) {
            return true;
        }
    }
    return false;
};

const isSector = (badge: string) => badge.startsWith(SectorPrefix);
const isIndustry = (badge: string) => badge.startsWith(IndustryPrefix);
const isIndicator = (badge: string) => !badge.startsWith(SectorPrefix) && !badge.startsWith(IndustryPrefix);

export const hasSector = (badges: BadgeSelected | undefined | null): boolean => {
    return hasBadge(badges, isSector);
};

export const hasIndustry = (badges: BadgeSelected | undefined | null): boolean => {
    return hasBadge(badges, isIndustry);
};

export const hasNoSectorOrIndustry = (badges: BadgeSelected | undefined | null): boolean => {
    return hasBadge(badges, isIndicator);
};

function setSelectedUIBadge(group: SelectedUIBadges, badge: string) {
    if (isSector(badge)) {
        group.selectedSectors.nodes[badge] = true;
    } else if (isIndustry(badge)) {
        group.selectedIndustries.nodes[badge] = true;
    } else {
        group.selectedIndicators.nodes[badge] = true;
    }
}

export const searchToBadges = (search?: TickerSearchRequestModel): SelectedUIBadges | null => {
    if (!search) return null;
    if (!search.badges) return null;
    const root = search.badges;
    if (root.operator !== BadgeOperator.And) {
        throw new Error('the search root should be an and');
    }

    const rootGroup: SelectedUIBadges = emptySelectedUIBadges();

    // this code assumes a flat structure, with not many levels and/ors
    root.badges.forEach((model) => {
        const g = asGroup(model);
        if (!g) {
            const v = asSingle(model);
            // must be the old version
            let oldBadge = v?.badge || '';
            if (!oldBadge) {
                log.error("Couldn't find the correct badge?");
                return;
            }
            setSelectedUIBadge(rootGroup, oldBadge);
            return;
        }

        g.badges.forEach((innerBadge) => {
            let v = asSingle(innerBadge);
            if (!v?.badge) {
                log.error('No badge!? A group?? not supported :(', innerBadge);
                return;
            }
            setSelectedUIBadge(rootGroup, v.badge);
        });
    });

    return rootGroup;
};

// function created to get searchRequest into a single, to then display as one-select-all-control
export function asSingleUIGroup(uiBadges: SelectedUIBadges): BadgeUIGroup {
    const result: BadgeUIGroup = {
        operator: BadgeOperator.And,
        nodes: {}
    };
    if (uiBadges) {
        Object.assign(result.nodes, uiBadges.selectedIndicators.nodes, uiBadges.selectedIndustries.nodes, uiBadges.selectedSectors.nodes);
    }
    return result;
}

// this is to invert from the BadgeUIGroup back to the SelectedUIBadges
export function splitSingleUIGroup(group: BadgeUIGroup): SelectedUIBadges {
    const result = emptySelectedUIBadges();

    if (group && group.nodes) {
        for (const badge in group.nodes) {
            setSelectedUIBadge(result, badge);
        }
    }
    return result;
}

export function badgesToSearch(groups: BadgeUIGroup[]): TickerSearchRequestModel {
    if (
        !groups ||
        groups.length === 0 ||
        // #loop this is to avoid the infinite loop of the input changing
        (groups.length === 1 && isEmpty(groups[0].nodes))
    ) {
        return {
            badges: {
                operator: BadgeOperator.And,
                badges: []
            }
        };
    }

    return {
        badges: {
            operator: BadgeOperator.And,
            badges: groups.filter((g) => !isEmpty(g.nodes)).map((g) => toBadgeCondition(g))
        }
    };
}

export function nodeToSelected(nodes: TreeNode | TreeNode[]): TreeSelectSelectionKeysType {
    let select: TreeSelectSelectionKeysType = {};
    if (Array.isArray(nodes)) {
        nodes.forEach((n) => {
            if (n.key) {
                select['' + n.key] = true;
            }
        });
    }
    return select;
}

export function toBadgeCondition(group: BadgeUIGroup): BadgeGroupConditionModel {
    let result: BadgeGroupConditionModel = {
        operator: group.operator,
        badges: []
    };

    if (group.nodes) {
        for (var n in group.nodes) {
            if (group.nodes[n] === true) {
                let value: BadgeValueConditionModel = {
                    badge: n
                };
                result.badges.push(value);
            }
        }
    }

    return result;
}

export function asGroup(model: BadgeConditionModel): BadgeGroupConditionModel | null {
    if ((model as BadgeGroupConditionModel).operator) return model as BadgeGroupConditionModel;
    return null;
}

export function asSingle(model: BadgeConditionModel): BadgeValueConditionModel | null {
    if ((model as BadgeValueConditionModel).badge) return model as BadgeValueConditionModel;
    return null;
}

export function printBadgeSelected(model: BadgeSelected) {
    let result = '';
    for (const b in model ) {
        if (model[b]) {
            result = result.concat(' '+ b);
        }
    }
    return result;
}