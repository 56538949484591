import { useMsal } from '@azure/msal-react';
import React, { PropsWithChildren, ReactElement } from 'react';
import { Provider } from 'use-http';
import { apiUrl, apiOptions, apiConfig } from './ApiConfig';

export const ApiProvider = ({ children }: PropsWithChildren<{}>): ReactElement => {
    const { instance, inProgress, accounts } = useMsal();
    
    apiOptions.interceptors = {
        request: async ({ options, url, path, route }) => {
            try {
                console.log(` ${path} - Requesting... ${inProgress}/${accounts.length}`)
                var tokenResponse = await instance.acquireTokenSilent({
                    scopes: apiConfig.scopes
                });
                
                var headers = new Headers(options.headers);
    
                if (tokenResponse && tokenResponse.accessToken) {
                    headers.append("Authorization", `Bearer ${tokenResponse.accessToken}`);
                }
                options.headers = headers;
            } catch {
                console.log(` ${path} FAILED TO GET TOKEN ${inProgress}/${accounts.length}`)
            }

            return options
        },
    };

    return (
        <Provider options={apiOptions} url={apiUrl}>{children}</Provider>
    )

}