import React, { useState } from 'react';
import { TickerMetaModel } from '../../api/Ophir';
import { TickersMetaGrid } from './_c/TickersGrid';
import { TickerMetaEdit } from './_c/TickerMetaEdit';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TickerMetaEditMode, TickerMetaEditProp } from './_c/TickerMetaEdit';
import { TickerPricesGrid } from './_c/TickerPricesGrid';
import { TickerIndicatorsGrid } from './_c/TickerIndicatorsGrid';
import { TickerBadgesGrid } from './_c/TickerBadgesGrid';
import { TickerDailySyncButton } from './_c/TickerDailySyncButton';

export const TickersPage = () => {
    const [metaDialogHeader, setMetaDialogHeader] = useState('New Ticker');
    const [metaEditDialog, showMetaEdit] = useState(false);
    const [priceDialog, showPriceDialog] = useState(false);
    const [badgeDialog, showBadgeDialog] = useState(false);
    const [indicatorDialog, showIndicatorDialog] = useState(false);
    const [selectedTicker, setSelectedTicker] = useState<TickerMetaModel>();
    const [metaEdit, setMetaEdit] = useState<TickerMetaEditProp>({
        mode: TickerMetaEditMode.Create
    });

    function onTickerSelected(ticker: TickerMetaModel) {
        console.log('clicked existing');
        setMetaEdit({
            mode: TickerMetaEditMode.Edit,
            ticker: ticker,
            onSaved: (tickerSaved) => showMetaEdit(false)
        });
        setMetaDialogHeader('Edit Ticker');
        showMetaEdit(true);
    }

    function openNew() {
        console.log('clicked new');
        setMetaEdit({
            mode: TickerMetaEditMode.Create,
            ticker: {
                companyName: '',
                isActive: true,
                flags: 0,
                industry: { id: 0, display: '' },
                sector: { id: 0, display: '' }
            },
            onSaved: (tickerSaved) => showMetaEdit(false)
        });
        setMetaDialogHeader('New Ticker');
        showMetaEdit(true);
    }

    function onShowInfo(ticker: TickerMetaModel, show: (value: true) => void): void {
        setSelectedTicker(ticker);
        show(true);
    }

    return (
        <div className="grid">
            <Dialog visible={metaEditDialog} header={metaDialogHeader} onHide={() => showMetaEdit(false)}>
                <TickerMetaEdit {...metaEdit} />
            </Dialog>

            <Dialog visible={priceDialog} header="Stock Prices" onHide={() => showPriceDialog(false)}>
                <TickerPricesGrid header={selectedTicker?.companyName} ticker={selectedTicker} />
            </Dialog>

            <Dialog visible={indicatorDialog} header="Stock Indicators" onHide={() => showIndicatorDialog(false)}>
                <TickerIndicatorsGrid header={selectedTicker?.companyName} ticker={selectedTicker} />
            </Dialog>

            <Dialog visible={badgeDialog} header="Stock Badges" onHide={() => showBadgeDialog(false)}>
                <TickerBadgesGrid header={selectedTicker?.companyName} ticker={selectedTicker} />
            </Dialog>

            <div className="p-col-12">
                <Toolbar
                    className="p-mb-4 p-toolbar"
                    left={
                        <>
                            <Button label="New" icon="pi pi-refresh" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
                            <TickerDailySyncButton />
                        </>
                    }
                />
                <div className="card">
                    <h5>Tickers Management</h5>
                    <TickersMetaGrid
                        onSelected={onTickerSelected}
                        onShowPrice={(ticker) => onShowInfo(ticker, showPriceDialog)}
                        onShowIndicators={(ticker) => onShowInfo(ticker, showIndicatorDialog)}
                        onShowBadges={(ticker) => onShowInfo(ticker, showBadgeDialog)}
                    />
                </div>
            </div>
        </div>
    );
};
