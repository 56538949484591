import { Column } from 'primereact/column';
import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { TickerSearchRequestModel, TickerGridModel, TradeDateModel } from '../../api/Ophir';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useGetTickerByDateSearchQuery, useGetTickerSearchQuery, useGetTickerStatsQuery } from '../../api/search/searchApi';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistance from 'date-fns/formatDistance/index';
import format from 'date-fns/format/index';
import { TickerExpandRow } from './TickerExpandRow';
import { PrimeIcons } from 'primereact/api';

import getLogger from '../../utilities/Logger';
import { EventType, logEvent } from '../../utilities/Analytics';

const log = getLogger('TickerGridAdv');
log.setLevel('debug');

export interface TickerAdvSearchGridProp {
    header: string;
    searchRequest: TickerSearchRequestModel;
}

export interface TickerGridDatedModel extends TickerGridModel {
    firstSeen?: Date;
    lastSeen?: Date;
    priceChange?: number;
    priceChangePc?: number;
    daysSeen?: number;
    daysSeenFormatted?: string;
}

export const TickerAdvSearchGrid = (props: TickerAdvSearchGridProp) => {
    const [limit] = useState(20);
    const [gridData, setGridData] = useState<TickerGridDatedModel[]>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({});

    const { isLoading, data } = useGetTickerSearchQuery(props.searchRequest);
    const { isLoading: isDateLoading, data: dateData, isError: isDateError } = useGetTickerByDateSearchQuery(props.searchRequest);
    const { data: latestDate } = useGetTickerStatsQuery(undefined);

    useEffect(() => {
        if (data && data.object) {
            let gridData: TickerGridDatedModel[] = [];
            data?.object.map((s) => gridData.push({ ...s }));
            if (!isDateLoading && !isDateError && dateData && dateData.object) {
                mergeTickerData(gridData, dateData.object);
            }
            calculatePriceChange(gridData);
            setGridData(gridData);
        }
    }, [data, dateData, isDateError, isDateLoading]);

    function calculatePriceChange(stocks: TickerGridDatedModel[]) {
        stocks.forEach((stock) => {
            const stockGrid = stock as TickerGridDatedModel;
            if (stock.price && stock.previousPrice) {
                stockGrid.priceChange = Math.round(100 * stock.price - 100 * stock.previousPrice) / 100;
                stockGrid.priceChangePc = Math.round((100 * 100 * stockGrid.priceChange) / stock.price) / 100;
            }
        });
    }

    function mergeTickerData(stock: TickerGridDatedModel[], dates: TradeDateModel[]): void {
        let currentDate = new Date();
        currentDate = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate());

        stock.forEach((m) => {
            // TODO Use updateQueryData
            var mms = m as TickerGridDatedModel;

            dates.forEach((s) => {
                let ms = mms;
                if (s.tickerId === m.tickerId && s.tradeDate) {
                    let tradeDate = new Date(s.tradeDate);
                    let hasChanged = false;
                    if (!ms.firstSeen || ms.firstSeen > tradeDate) {
                        ms.firstSeen = tradeDate;
                        hasChanged = true;
                    }
                    if (!ms.lastSeen || ms.lastSeen < tradeDate) {
                        ms.lastSeen = tradeDate;
                        hasChanged = true;
                    }
                    if (ms.lastSeen && ms.firstSeen && hasChanged) {
                        ms.daysSeen = differenceInDays(currentDate, ms.firstSeen);
                        if (ms.daysSeen === 0) {
                            ms.daysSeenFormatted = 'today';
                        } else {
                            ms.daysSeenFormatted = formatDistance(currentDate, ms.firstSeen);
                        }
                    }
                }
            });
        });
    }

    function expandTemplate(data: TickerGridDatedModel) {
        return <TickerExpandRow data={data} />;
    }

    function companyNameExpander(data: TickerGridDatedModel) {
        let expanded = expandedRows[data.tickerId || ''];

        return (
            <span className="flex align-items-center">
                <span className="hidden p-d-sm-table">{data.companyName}</span>
                <Button
                    className="p-button-rounded p-button-sm p-button-icon-only p-button-text"
                    icon={expanded ? PrimeIcons.ANGLE_DOWN : PrimeIcons.ANGLE_RIGHT}
                    onClick={(e) => {
                        if (!data.tickerId) return;
                        if (expandedRows[data.tickerId || '']) {
                            collapseTicker(data.tickerId);
                        } else {
                            expandTicker(data.tickerId);
                        }
                    }}
                />
            </span>
        );
    }

    function collapseTicker(tickerId: string) {
        let newValue = { ...expandedRows };
        delete newValue[tickerId];
        setExpandedRows(newValue);
        logClick('Collapse grid to hide stock details');
    }

    function expandTicker(tickerId: string) {
        setExpandedRows({ ...expandedRows, [tickerId]: true });
        logClick('Expand grid to see stock details');
    }

    function toggleExpandTicker(tickerId: string) {
        if (expandedRows[tickerId]) {
            collapseTicker(tickerId);
        } else {
            expandTicker(tickerId);
        }
    }

    let dt: DataTable | null = null;

    function exportCSV() {
        if (dt) {
            logClick('Exporting data to CSV');
            dt.exportCSV({ selectionOnly: false });
        }
    }

    function logClick(value: string) {
        logEvent('#grid', value, EventType.Click);
    }

    return (
        <DataTable
            ref={(el) => {
                dt = el;
            }}
            value={gridData}
            selectionMode="single"
            dataKey="tickerId"
            key="tickerId"
            paginator
            rows={limit}
            rowsPerPageOptions={[20, 40, 80]}
            rowHover={false}
            onRowClick={(e) => toggleExpandTicker(e.data.tickerId)}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tickers"
            emptyMessage="No stocks found"
            loading={isLoading}
            globalFilter={globalFilter}
            expandedRows={expandedRows}
            onRowExpand={(e) => expandTicker(e.data.tickerId)}
            onRowCollapse={(e) => collapseTicker(e.data.tickerId)}
            rowExpansionTemplate={expandTemplate}
            exportFilename="shareImpulse"
            sortField="firstSeen"
            sortOrder={-1}
            responsiveLayout="scroll"
            header={
                <div className="table-header">
                    <div className="flex p-p-3">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText
                                type="search"
                                value={globalFilter}
                                onChange={(e) => {
                                    if (e.target.value.length >= 3) logClick('Grid search: ' + e.target.value);
                                    setGlobalFilter(e.target.value);
                                }}
                                placeholder="Stock name or ticker"
                            />
                            {latestDate && (
                                <span className="hidden p-d-md-inline">
                                    &nbsp;&nbsp;Last updated {format(new Date(latestDate.mostRecentSync as any), 'dd MMMM yyyy')}
                                </span>
                            )}
                        </span>
                        <Button
                            type="button"
                            icon="pi pi-file"
                            className="hidden p-d-sm-inline p-ml-auto"
                            onClick={() => exportCSV()}
                            data-pr-tooltip="CSV"
                        />
                    </div>
                </div>
            }
            tableClassName=""
            className="p-datatable-frozen-tbody"
            rowClassName={(rowData) => {
                return {
                    'grid-days-3': dateData?.object && rowData.daysSeen === 0,
                    'grid-days-1': dateData?.object && (rowData.daysSeen === 1 || rowData.daysSeen === 2),
                    'grid-days-0': !dateData?.object || rowData.daysSeen > 2
                };
            }}>
            <Column
                field="ticker"
                header="Ticker"
                sortable
                body={(rowData) => (
                    <>
                        <span className="hidden p-d-sm-inline">{rowData.exchange}:</span>
                        {rowData.ticker}
                    </>
                )}
            />
            <Column field="price" header="Price" sortable body={(rowData) => '$' + rowData.price} />
            <Column
                field="priceChangePc"
                header="Change"
                sortable
                body={(rowData: TickerGridDatedModel) => {
                    let direction = 'up';
                    if (!rowData.priceChange) rowData.priceChange = 0;
                    if (!rowData.priceChangePc) rowData.priceChangePc = 0;

                    let value = '';
                    if (rowData.priceChange < 0) {
                        direction = 'down';
                        value = '-';
                    }

                    value += Math.abs(rowData.priceChangePc);
                    value += '%';

                    return (
                        <React.Fragment>
                            <i className={`pi pi-arrow-${direction} hidden p-d-sm-inline`} /> {value}
                        </React.Fragment>
                    );
                }}
            />
            <Column field="firstSeen" header="First Seen" body={(rowData) => <React.Fragment>{rowData.daysSeenFormatted}</React.Fragment>} sortable />
            <Column expander field="companyName" header="Name" sortable body={companyNameExpander} />
        </DataTable>
    );
};
