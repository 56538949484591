import TreeNode from 'primereact/treenode';

let industryCache: TreeNode[];
let sectorCache: TreeNode[];
let indicatorsCache: TreeNode[];

export const IndustryPrefix = 'Industry:';
export const SectorPrefix = 'Sector:';

export const industryNodes = (): TreeNode[] => {
    if (industryCache) return industryCache;

    industryCache = searchBadges(badgeNodes, (node) => (node.key as string)?.startsWith(IndustryPrefix));

    return industryCache;
};

export const sectorNodes = (): TreeNode[] => {
    if (sectorCache) return sectorCache;

    sectorCache = searchBadges(badgeNodes, (node) => (node.key as string)?.startsWith(SectorPrefix));

    return sectorCache;
};

export const hasSectorNodes = (nodes: TreeNode[] | undefined | null): boolean => {
    if (!nodes || nodes.length === 0) return false;
    return nodes.some((n) => (n.key as string)?.startsWith(SectorPrefix));
};

export const hasIndustryNodes = (nodes: TreeNode[] | undefined | null) => {
    if (!nodes || nodes.length === 0) return false;
    return nodes.some((n) => (n.key as string)?.startsWith(IndustryPrefix));
};

export const indicatorsNodes = (): TreeNode[] => {
    if (indicatorsCache) return indicatorsCache;

    const skipParents = ['g-StockD'];

    // we make it as a local first to avoid multiple push against the same array (in case of async stuff)
    let local: TreeNode[] = [];
    badgeNodes.forEach((n) => {
        if (skipParents.some((p) => p === n.key)) {
            return;
        }
        local.push(n);
    });

    indicatorsCache = local;

    return indicatorsCache;
};

export const allNodes = () => badgeNodes;

export function findByKey(key: string, nodes?: TreeNode[]): TreeNode | null {
    if (!key) return null;
    return searchBadge(nodes || badgeNodes, (node) => node.key === key);
}

export function findByPrefix(prefix: string, nodes?: TreeNode[]): TreeNode | null {
    return searchBadge(nodes || badgeNodes, (node) => (node.key as string).toString().startsWith(prefix));
}

export function findByPrefixInArray(prefix: string, badges?: string[]): string | undefined {
    return (badges || []).find((b: string) => b.startsWith(prefix));
}

// this returns the first badge found
export function searchBadge(nodes: TreeNode[], equality: (v: TreeNode) => boolean): TreeNode | null {
    let found: TreeNode | null = null;
    nodes = nodes || badgeNodes;
    nodes.some((n) => {
        if (equality(n)) {
            found = n;
            return true;
        }
        if (n.children && n.children.length > 0) {
            const childrenFound = searchBadge(n.children, equality);
            if (childrenFound) {
                found = childrenFound;
                return true;
            }
        }
        return false;
    });

    return found;
}

// this returns all matching badges
export function searchBadges(nodes: TreeNode[], equality: (v: TreeNode) => boolean): TreeNode[] {
    let found: TreeNode[] = [];
    nodes = nodes || badgeNodes;
    nodes.forEach((n) => {
        if (equality(n)) {
            found.push(n);
        }
        if (n.children && n.children.length > 0) {
            searchBadges(n.children, equality).forEach((f) => found.push(f));
        }
    });

    return found;
}

export const badgeNodes: TreeNode[] = [
    {
        key: 'g-SMA',
        label: 'Simple Moving Average (SMA)',
        data: 'SMA-Group',
        selectable: false,
        icon: 'pi pi-fw- pi-chart',
        children: [
            {
                key: 'g-SMA-3',
                label: 'SMA Cross Over',
                selectable: false,
                children: [
                    {
                        key: 'SMA13AboveSMA50',
                        data: 'SMA13AboveSMA50',
                        label: 'SMA 13 Above SMA 50',
                        children: []
                    },
                    {
                        key: 'SMA30AboveSMA100',
                        data: 'SMA30AboveSMA100',
                        label: 'SMA 30 Above SMA 100',
                        children: []
                    },
                    {
                        key: 'SMA50AboveSMA150',
                        data: 'SMA50AboveSMA150',
                        label: 'SMA 50 Above SMA 150',
                        children: []
                    },
                    {
                        key: 'SMA50AboveSMA200',
                        data: 'SMA50AboveSMA200',
                        label: 'SMA 50 Above SMA 200',
                        children: []
                    },
                    {
                        key: 'SMA100AboveSMA200',
                        data: 'SMA100AboveSMA200',
                        label: 'SMA 100 Above SMA 200',
                        children: []
                    }
                ]
            },
            {
                key: 'g-SMA-Under',
                label: 'SMA Cross Under',
                selectable: false,
                children: [
                    {
                        key: 'SMA13UnderSMA50',
                        data: 'SMA13UnderSMA50',
                        label: 'SMA 13 Under SMA 50',
                        children: []
                    },
                    {
                        key: 'SMA30UnderSMA100',
                        data: 'SMA30UnderSMA100',
                        label: 'SMA 30 Under SMA 100',
                        children: []
                    },
                    {
                        key: 'SMA50UnderSMA150',
                        data: 'SMA50UnderSMA150',
                        label: 'SMA 50 Under SMA 150',
                        children: []
                    },
                    {
                        key: 'SMA50UnderSMA200',
                        data: 'SMA50UnderSMA200',
                        label: 'SMA 50 Under SMA 200',
                        children: []
                    }
                ]
            },

            {
                key: 'g-SMA-4',
                label: 'Rising',
                selectable: false,
                children: [
                    {
                        key: 'SMA26TrendUp',
                        data: 'SMA26TrendUp',
                        label: 'SMA 26 Rising',
                        children: []
                    },
                    {
                        key: 'SMA50TrendUp',
                        data: 'SMA50TrendUp',
                        label: 'SMA 50 Rising',
                        children: []
                    },
                    {
                        key: 'SMA100TrendUp',
                        data: 'SMA100TrendUp',
                        label: 'SMA 100 Rising',
                        children: []
                    },
                    {
                        key: 'SMA150TrendUp',
                        data: 'SMA150TrendUp',
                        label: 'SMA 150 Rising',
                        children: []
                    },
                    {
                        key: 'SMA200TrendUp',
                        data: 'SMA200TrendUp',
                        label: 'SMA 200 Rising',
                        children: []
                    }
                ]
            },
            {
                key: 'g-SMA-5',
                label: 'Falling',
                selectable: false,
                children: [
                    {
                        key: 'SMA26TrendDown',
                        data: 'SMA26TrendDown',
                        label: 'SMA 26 Falling',
                        children: []
                    },
                    {
                        key: 'SMA50TrendDown',
                        data: 'SMA50TrendDown',
                        label: 'SMA 50 Falling',
                        children: []
                    },
                    {
                        key: 'SMA100TrendDown',
                        data: 'SMA100TrendDown',
                        label: 'SMA 100 Falling',
                        children: []
                    },
                    {
                        key: 'SMA150TrendDown',
                        data: 'SMA150TrendDown',
                        label: 'SMA 150 Falling',
                        children: []
                    },
                    {
                        key: 'SMA200TrendDown',
                        data: 'SMA200TrendDown',
                        label: 'SMA 200 Falling',
                        children: []
                    }
                ]
            }
        ]
    },
    {
        key: 'g-Price',
        label: 'Price',
        data: 'LowestPrice-Group',
        selectable: false,
        icon: 'chart',
        children: [
            {
                key: 'g-SMA-1',
                label: 'Price Above SMA',
                selectable: false,
                children: [
                    {
                        key: 'PriceAboveSMA13',
                        data: 'PriceAboveSMA13',
                        label: 'Price Above SMA 13',
                        children: []
                    },
                    {
                        key: 'PriceAboveSMA26',
                        data: 'PriceAboveSMA26',
                        label: 'Price Above SMA 26',
                        children: []
                    },
                    {
                        key: 'PriceAboveSMA50',
                        data: 'PriceAboveSMA50',
                        label: 'Price Above SMA 50',
                        children: []
                    },
                    {
                        key: 'PriceAboveSMA100',
                        data: 'PriceAboveSMA100',
                        label: 'Price Above SMA 100',
                        children: []
                    },
                    {
                        key: 'PriceAboveSMA145',
                        data: 'PriceAboveSMA145',
                        label: 'Price Above SMA 145',
                        children: []
                    },
                    {
                        key: 'PriceAboveSMA150',
                        data: 'PriceAboveSMA150',
                        label: 'Price Above SMA 150',
                        children: []
                    },
                    {
                        key: 'PriceAboveSMA200',
                        data: 'PriceAboveSMA200',
                        label: 'Price Above SMA 200',
                        children: []
                    }
                ]
            },
            {
                key: 'g-SMA-2',
                label: 'Price Below SMA',
                selectable: false,
                children: [
                    {
                        key: 'PriceBelowSMA200',
                        data: 'PriceBelowSMA200',
                        label: 'Price Below SMA 200',
                        children: []
                    },
                    {
                        key: 'PriceBelowSMA145',
                        data: 'PriceBelowSMA145',
                        label: 'Price Below SMA 145',
                        children: []
                    },
                    {
                        key: 'PriceBelowSMA100',
                        data: 'PriceBelowSMA100',
                        label: 'Price Below SMA 100',
                        children: []
                    },
                    {
                        key: 'PriceBelowSMA50',
                        data: 'PriceBelowSMA50',
                        label: 'Price Below SMA 50',
                        children: []
                    }
                ]
            },

            {
                key: 'g-Price-1',
                label: 'Price Low',
                selectable: false,
                children: [
                    {
                        key: 'AtLowest4WeekPrice',
                        data: 'AtLowest4WeekPrice',
                        label: 'At the 4 week lowest price',
                        children: []
                    },
                    {
                        key: 'AtLowest26WeekPrice',
                        data: 'AtLowest26WeekPrice',
                        label: 'At the 26 week lowest price',
                        children: []
                    },
                    {
                        key: 'AtLowest52WeekPrice',
                        data: 'AtLowest52WeekPrice',
                        label: 'At the 52 week lowest price',
                        children: []
                    },
                    {
                        key: 'NearingLow52WeekPrice10',
                        data: 'NearingLow52WeekPrice10',
                        label: 'Around 10% of 52 week lowest price',
                        children: []
                    },
                    {
                        key: 'NearingLow52WeekPrice20',
                        data: 'NearingLow52WeekPrice20',
                        label: 'Around 20% of 52 week lowest price',
                        children: []
                    }
                ]
            },
            {
                key: 'g-Price-2',
                label: 'Price High',
                selectable: false,
                children: [
                    {
                        key: 'AtHighest4WeekPrice',
                        data: 'AtHighest4WeekPrice',
                        label: 'At the 4 week highest price',
                        children: []
                    },
                    {
                        key: 'AtHighest26WeekPrice',
                        data: 'AtHighest26WeekPrice',
                        label: 'At the 26 week highest price',
                        children: []
                    },
                    {
                        key: 'AtHighest52WeekPrice',
                        data: 'AtHighest52WeekPrice',
                        label: 'At the 52 week highest price',
                        children: []
                    },
                    {
                        key: 'NearingHigh52WeekPrice10',
                        data: 'NearingHigh52WeekPrice10',
                        label: 'Around 10% of 52 week highest price',
                        children: []
                    },
                    {
                        key: 'NearingHigh52WeekPrice20',
                        data: 'NearingHigh52WeekPrice20',
                        label: 'Around 20% of 52 week high price',
                        children: []
                    }
                ]
            }
        ]
    },
    {
        key: 'g-Volume',
        label: 'Volume',
        data: 'Volume-Group',
        selectable: false,
        icon: 'pi pi-chart',
        children: [
            {
                key: 'g-Volume-1',
                label: 'Volume Low',
                selectable: false,
                children: [
                    {
                        key: 'Volume20LowerThan30DaysAvg',
                        data: 'Volume20LowerThan30DaysAvg',
                        label: 'Trading volume lesser than 20% of the 30 day volume average',
                        children: []
                    },
                    {
                        key: 'Volume50LowerThan30DaysAvg',
                        data: 'Volume50LowerThan30DaysAvg',
                        label: 'Trading volume lesser than 50% of the 30 day volume average',
                        children: []
                    }
                ]
            },
            {
                key: 'g-Volume-2',
                label: 'Volume High',
                selectable: false,
                children: [
                    {
                        key: 'Volume150HigherThan30DaysAvg',
                        data: 'Volume150HigherThan30DaysAvg',
                        label: 'Trading volume greater than 150% of the 30 day volume average',
                        children: []
                    },
                    {
                        key: 'Volume200HigherThan30DaysAvg',
                        data: 'Volume200HigherThan30DaysAvg',
                        label: 'Trading volume greater than 200% of the 30 day volume average',
                        children: []
                    }
                ]
            }
        ]
    },
    {
        key: 'g-RelativePerformanceIndex',
        label: 'Relative Performance',
        selectable: false,
        children: [
            {
                key: 'g-RelativePerformanceIndex-SMA200',
                data: 'g-RelativePerformanceIndex-SMA200',
                label: 'Relative Performance vs Index',
                selectable: false,
                children: [
                    {
                        key: 'RelativeStrengthSMA200',
                        data: 'RelativeStrengthSMA200',
                        label: 'Stronger than Index',
                        children: []
                    }
                ]
            }
        ]
    },
    {
        key: 'g-StockD',
        label: 'Stock Details',
        data: 'g-StockDetails',
        selectable: false,
        icon: 'pi pi-chart',
        children: [
            {
                key: 'g-StockD-Exchange',
                label: 'Exchange',
                selectable: false,
                children: [
                    { key: 'Exchange:ASX', data: 'Exchange:ASX', label: 'ASX', children: [] },
                    { key: 'Exchange:NASDAQ', data: 'Exchange:NASDAQ', label: 'NASDAQ', children: [] },
                    { key: 'Exchange:NYSE', data: 'Exchange:NYSE', label: 'NYSE', children: [] }
                ]
            },
            {
                key: 'g-StockD-1',
                label: 'Sector',
                selectable: false,
                children: [
                    { key: 'Sector:Technology', data: 'Sector:Technology', label: 'Technology', children: [] },
                    {
                        key: 'Sector:BasicMaterials',
                        data: 'Sector:BasicMaterials',
                        label: 'Basic Materials',
                        children: []
                    },
                    { key: 'Sector:NoMD', data: 'Sector:NoMD', label: 'Fund', children: [] },
                    {
                        key: 'Sector:Consumer,Non-cyclical',
                        data: 'Sector:Consumer,Non-cyclical',
                        label: 'Consumer, Non-cyclical',
                        children: []
                    },
                    { key: 'Sector:Industrial', data: 'Sector:Industrial', label: 'Industrial', children: [] },
                    { key: 'Sector:Financial', data: 'Sector:Financial', label: 'Financial', children: [] },
                    { key: 'Sector:Utilities', data: 'Sector:Utilities', label: 'Utilities', children: [] },
                    {
                        key: 'Sector:Consumer,Cyclical',
                        data: 'Sector:Consumer,Cyclical',
                        label: 'Consumer, Cyclical',
                        children: []
                    },
                    { key: 'Sector:Energy', data: 'Sector:Energy', label: 'Energy', children: [] },
                    {
                        key: 'Sector:Communications',
                        data: 'Sector:Communications',
                        label: 'Communications',
                        children: []
                    },
                    { key: 'Sector:Diversified', data: 'Sector:Diversified', label: 'Diversified', children: [] }
                ]
            },
            {
                key: 'g-StockD-2',
                label: 'Industry',
                selectable: false,
                children: [
                    { key: 'Industry:Software', data: 'Industry:Software', label: 'Software', children: [] },
                    { key: 'Industry:Mining', data: 'Industry:Mining', label: 'Mining', children: [] },
                    { key: 'Industry:NoMD', data: 'Industry:NoMD', label: 'Fund', children: [] },
                    { key: 'Industry:Food', data: 'Industry:Food', label: 'Food', children: [] },
                    {
                        key: 'Industry:BuildingMaterials',
                        data: 'Industry:BuildingMaterials',
                        label: 'Building Materials',
                        children: []
                    },
                    { key: 'Industry:REITS', data: 'Industry:REITS', label: 'REITS', children: [] },
                    { key: 'Industry:Electric', data: 'Industry:Electric', label: 'Electric', children: [] },
                    {
                        key: 'Industry:Engineering&Construction',
                        data: 'Industry:Engineering&Construction',
                        label: 'Engineering&Construction',
                        children: []
                    },
                    { key: 'Industry:Airlines', data: 'Industry:Airlines', label: 'Airlines', children: [] },
                    { key: 'Industry:Oil&Gas', data: 'Industry:Oil&Gas', label: 'Oil&Gas', children: [] },
                    {
                        key: 'Industry:Entertainment',
                        data: 'Industry:Entertainment',
                        label: 'Entertainment',
                        children: []
                    },
                    {
                        key: 'Industry:MiscellaneousManufactur',
                        data: 'Industry:MiscellaneousManufactur',
                        label: 'Miscellaneous Manufactur',
                        children: []
                    },
                    { key: 'Industry:Electronics', data: 'Industry:Electronics', label: 'Electronics', children: [] },
                    {
                        key: 'Industry:CommercialServices',
                        data: 'Industry:CommercialServices',
                        label: 'Commercial Services',
                        children: []
                    },
                    {
                        key: 'Industry:Packaging&Containers',
                        data: 'Industry:Packaging&Containers',
                        label: 'Packaging&Containers',
                        children: []
                    },
                    {
                        key: 'Industry:DiversifiedFinanServ',
                        data: 'Industry:DiversifiedFinanServ',
                        label: 'Diversified Finan Serv',
                        children: []
                    },
                    {
                        key: 'Industry:Healthcare-Products',
                        data: 'Industry:Healthcare-Products',
                        label: 'Healthcare-Products',
                        children: []
                    },
                    { key: 'Industry:Banks', data: 'Industry:Banks', label: 'Banks', children: [] },
                    { key: 'Industry:Pipelines', data: 'Industry:Pipelines', label: 'Pipelines', children: [] },
                    { key: 'Industry:Retail', data: 'Industry:Retail', label: 'Retail', children: [] },
                    { key: 'Industry:Computers', data: 'Industry:Computers', label: 'Computers', children: [] },
                    {
                        key: 'Industry:AutoParts&Equipment',
                        data: 'Industry:AutoParts&Equipment',
                        label: 'Auto Parts&Equipment',
                        children: []
                    },
                    { key: 'Industry:Insurance', data: 'Industry:Insurance', label: 'Insurance', children: [] },
                    {
                        key: 'Industry:Distribution/Wholesale',
                        data: 'Industry:Distribution/Wholesale',
                        label: 'Distribution/Wholesale',
                        children: []
                    },
                    {
                        key: 'Industry:Transportation',
                        data: 'Industry:Transportation',
                        label: 'Transportation',
                        children: []
                    },
                    {
                        key: 'Industry:Pharmaceuticals',
                        data: 'Industry:Pharmaceuticals',
                        label: 'Pharmaceuticals',
                        children: []
                    },
                    { key: 'Industry:Housewares', data: 'Industry:Housewares', label: 'Housewares', children: [] },
                    { key: 'Industry:Iron/Steel', data: 'Industry:Iron/Steel', label: 'Iron/Steel', children: [] },
                    { key: 'Industry:Internet', data: 'Industry:Internet', label: 'Internet', children: [] },
                    {
                        key: 'Industry:Telecommunications',
                        data: 'Industry:Telecommunications',
                        label: 'Telecommunications',
                        children: []
                    },
                    { key: 'Industry:Agriculture', data: 'Industry:Agriculture', label: 'Agriculture', children: [] },
                    {
                        key: 'Industry:PrivateEquity',
                        data: 'Industry:PrivateEquity',
                        label: 'Private Equity',
                        children: []
                    },
                    { key: 'Industry:Coal', data: 'Industry:Coal', label: 'Coal', children: [] },
                    {
                        key: 'Industry:Biotechnology',
                        data: 'Industry:Biotechnology',
                        label: 'Biotechnology',
                        children: []
                    },
                    { key: 'Industry:LeisureTime', data: 'Industry:LeisureTime', label: 'Leisure Time', children: [] },
                    { key: 'Industry:Lodging', data: 'Industry:Lodging', label: 'Lodging', children: [] },
                    {
                        key: 'Industry:EnvironmentalControl',
                        data: 'Industry:EnvironmentalControl',
                        label: 'Environmental Control',
                        children: []
                    },
                    { key: 'Industry:Beverages', data: 'Industry:Beverages', label: 'Beverages', children: [] },
                    {
                        key: 'Industry:HoldingCompanies-Divers',
                        data: 'Industry:HoldingCompanies-Divers',
                        label: 'Holding Companies-Divers',
                        children: []
                    },
                    {
                        key: 'Industry:Healthcare-Services',
                        data: 'Industry:Healthcare-Services',
                        label: 'Healthcare-Services',
                        children: []
                    },
                    { key: 'Industry:RealEstate', data: 'Industry:RealEstate', label: 'Real Estate', children: [] },
                    { key: 'Industry:Chemicals', data: 'Industry:Chemicals', label: 'Chemicals', children: [] },
                    { key: 'Industry:HomeBuilders', data: 'Industry:HomeBuilders', label: 'Home Builders', children: [] },
                    { key: 'Industry:Media', data: 'Industry:Media', label: 'Media', children: [] },
                    {
                        key: 'Industry:ElectricalCompo&Equip',
                        data: 'Industry:ElectricalCompo&Equip',
                        label: 'Electrical Compo&Equip',
                        children: []
                    },
                    {
                        key: 'Industry:InvestmentCompanies',
                        data: 'Industry:InvestmentCompanies',
                        label: 'Investment Companies',
                        children: []
                    },
                    {
                        key: 'Industry:MetalFabricate/Hardware',
                        data: 'Industry:MetalFabricate/Hardware',
                        label: 'Metal Fabricate/Hardware',
                        children: []
                    },
                    {
                        key: 'Industry:Semiconductors',
                        data: 'Industry:Semiconductors',
                        label: 'Semiconductors',
                        children: []
                    },
                    { key: 'Industry:Apparel', data: 'Industry:Apparel', label: 'Apparel', children: [] },
                    {
                        key: 'Industry:Cosmetics/PersonalCare',
                        data: 'Industry:Cosmetics/PersonalCare',
                        label: 'Cosmetics/Personal Care',
                        children: []
                    },
                    {
                        key: 'Industry:AutoManufacturers',
                        data: 'Industry:AutoManufacturers',
                        label: 'Auto Manufacturers',
                        children: []
                    },
                    {
                        key: 'Industry:HomeFurnishings',
                        data: 'Industry:HomeFurnishings',
                        label: 'Home Furnishings',
                        children: []
                    },
                    { key: 'Industry:Shipbuilding', data: 'Industry:Shipbuilding', label: 'Shipbuilding', children: [] },
                    {
                        key: 'Industry:Aerospace/Defense',
                        data: 'Industry:Aerospace/Defense',
                        label: 'Aerospace/Defense',
                        children: []
                    },
                    {
                        key: 'Industry:Oil&GasServices',
                        data: 'Industry:Oil&GasServices',
                        label: 'Oil&Gas Services',
                        children: []
                    },
                    { key: 'Industry:Advertising', data: 'Industry:Advertising', label: 'Advertising', children: [] }
                ]
            },
            {
                key: 'g-StockD-3',
                label: 'Trading Days',
                selectable: false,
                children: [
                    {
                        key: 'MoreThan255Prices',
                        data: 'MoreThan255Prices',
                        label: 'With more than 255 trading days',
                        children: []
                    },
                    {
                        key: 'LessThan255Prices',
                        data: 'LessThan255Prices',
                        label: 'With less than 255 trading days',
                        children: []
                    }
                ]
            }
        ]
    }
];
