import { InputText } from 'primereact/inputtext';
import React from 'react';
import { CachePolicies, useFetch } from 'use-http';
import { LookupCategory, TickerMetaModel } from '../../../api/Ophir';
import { ExchangeDropdown } from '../../../components/ExchangeDropdown';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { InputSwitch } from 'primereact/inputswitch';
import { TagLookupDropdown } from '../../../components/Lookups/TagDropdown';

export enum TickerMetaEditMode {
    Create,
    Edit
}

export interface TickerMetaSaved {
    (ticker: TickerMetaModel): void;
}

export interface TickerMetaEditProp {
    mode: TickerMetaEditMode;
    ticker?: TickerMetaModel;
    onSaved?: TickerMetaSaved;
}

export const TickerMetaEdit = (props: TickerMetaEditProp) => {
    const form = useForm<TickerMetaModel>({
        defaultValues: props.ticker
    });

    const { post, put, loading } = useFetch('management/ticker', {
        cachePolicy: CachePolicies.NO_CACHE
    });
    const {
        register,
        handleSubmit,
        control,
        formState: { errors }
    } = form;

    const onSubmit = async (data: TickerMetaModel) => {
        console.log(data);
        switch (props.mode) {
            case TickerMetaEditMode.Create:
                await post(data);
                console.log('created');

                return;
            case TickerMetaEditMode.Edit:
                await put(data.tickerId, data);
                console.log('edited');
                return;
        }
        return;
    };

    const onSync = async (type: string) => {
        if (!props.ticker || props.mode !== TickerMetaEditMode.Edit) return;
        console.log('requested sync: ' + type);
        try {
            await post(`${props.ticker.tickerId}/price/${type}`, {
                tickerId: props.ticker.tickerId,
                endDate: new Date().toUTCString()
            });
        } catch {
            console.log('error sync');
        }
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card p-fluid">
                    <div className="field">
                        <label htmlFor="name">Ticker</label>

                        <InputText disabled={props.mode === TickerMetaEditMode.Edit} {...register('ticker', { required: true })} />
                        {errors.ticker && <span>This field is required</span>}
                    </div>
                    <div className="field">
                        <label htmlFor="exchange">Exchange</label>
                        <Controller
                            control={control}
                            name="exchange"
                            rules={{
                                required: true
                            }}
                            render={({ field }) => (
                                <ExchangeDropdown
                                    value={field.value}
                                    disabled={props.mode === TickerMetaEditMode.Edit}
                                    onSelected={(e) => form.setValue('exchange', e.value)}
                                />
                            )}
                        />
                        {errors.exchange && <span>This field is required</span>}
                    </div>
                    <div className="field">
                        <label htmlFor="sector">Sector</label>
                        <Controller
                            control={control}
                            name="sector"
                            rules={{
                                required: true
                            }}
                            render={({ field }) => (
                                <TagLookupDropdown
                                    tag={LookupCategory.Sector}
                                    dataKey={'sector'}
                                    value={field.value}
                                    onSelected={(e) => form.setValue('sector', e)}
                                />
                            )}
                        />
                        {errors.sector && <span>This field is required</span>}
                    </div>
                    <div className="field">
                        <label htmlFor="industry">Industry</label>
                        <Controller
                            control={control}
                            name="industry"
                            rules={{
                                required: true
                            }}
                            render={({ field }) => (
                                <TagLookupDropdown
                                    tag={LookupCategory.Industry}
                                    dataKey={'industry'}
                                    value={field.value}
                                    onSelected={(e) => form.setValue('industry', e)}
                                />
                            )}
                        />
                        {errors.industry && <span>This field is required</span>}
                    </div>
                    <div className="field">
                        <label htmlFor="companyName">Company Name</label>
                        <InputText
                            id="companyName"
                            className={classNames({
                                'p-invalid': errors.companyName
                            })}
                            {...register('companyName', { required: true })}
                        />
                        {errors.companyName && <span>This field is required</span>}
                    </div>
                    <div className="field">
                        <label htmlFor="flags">Flags (keep 0, only use 1 if index)</label>
                        <InputText
                            id="flags"
                            className={classNames({
                                'p-invalid': errors.flags
                            })}
                            {...register('flags', { required: true, min: 0, max: 1 })}
                        />
                        {errors.flags && <span>This field is required</span>}
                    </div>
                    <div className="field">
                        <label htmlFor="isActive">Is Active</label>
                        <Controller
                            control={control}
                            name="isActive"
                            rules={{}}
                            render={({ field }) => <InputSwitch checked={field.value} onChange={(e) => form.setValue('isActive', e.value)} />}
                        />
                    </div>
                    <div
                        className={classNames({
                            hidden: props.mode !== TickerMetaEditMode.Edit
                        })}>
                        <div className="field">
                            <div className="p-inputgroup">
                                <label htmlFor="lastPriceUpdate" className="p-mr-2">
                                    Last Price
                                </label>
                                <InputText placeholder="Keyword" disabled {...register('lastPriceUpdate', {})} />
                                <Button
                                    type="button"
                                    label="Daily"
                                    className="p-button-secondary"
                                    icon="pi pi-refresh"
                                    iconPos="right"
                                    onClick={async () => await onSync('eod-sync')}
                                />
                                <Button
                                    type="button"
                                    label="Realtime"
                                    className="p-button-secondary"
                                    icon="pi pi-clock"
                                    iconPos="right"
                                    onClick={async () => await onSync('realtime-sync')}
                                />
                            </div>
                        </div>
                        <div className="p-field">
                            <div className="p-inputgroup">
                                <label htmlFor="lastSync" className="p-mr-2">
                                    Full sync
                                </label>
                                <InputText placeholder="Keyword" disabled {...register('lastDataSync', {})} />
                                <Button
                                    type="button"
                                    label="Full sync"
                                    className="p-button-danger"
                                    icon="pi pi-refresh"
                                    iconPos="right"
                                    onClick={async () => onSync('full-sync')}
                                />
                            </div>
                        </div>
                    </div>
                    <Button type="submit" disabled={loading} className="p-button-primary p-mr-2 p-mb-2">
                        Save
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};
