import React from 'react';
import { Card } from 'primereact/card';
import { SearchTemplateList } from '../../components/SearchTemplates/SearchTemplateList';
import { SavedSearchCardList } from '../../components/SavedSearches/SavedSearchCardList';
import { ProgressBar } from 'primereact/progressbar';

export const MarketStatsDashboard = () => {
    return (
        <>
            <div className="flex flex-column flex-grow-1 m-2">
                <ProgressBar value={30} style={{ backgroundColor: '#D32F2F' }} showValue={false} />
                <div className="flex justify-content-between">
                    <div>300 declining</div>
                    <div>2221 advancing</div>
                </div>
            </div>
            <div className="flex flex-column flex-grow-1 m-2">
                <ProgressBar value={54} style={{ backgroundColor: '#D32F2F' }} showValue={false} />
                <div className="flex justify-content-between">
                    <div>435 new low</div>
                    <div>600 new high</div>
                </div>
            </div>
            <div className="flex flex-column flex-grow-1 m-2">
                <ProgressBar value={35} style={{ backgroundColor: '#D32F2F' }} showValue={false} />
                <div className="flex justify-content-between">
                    <div>2433 above SMA 50</div>
                    <div>5556 below SMA 50</div>
                </div>
            </div>
            <div className="flex flex-column flex-grow-1 m-2">
                <ProgressBar value={80} style={{ backgroundColor: '#D32F2F' }} showValue={false} />
                <div className="flex justify-content-between">
                    <div>3456 volume above avg</div>
                    <div>261 volume below avg</div>
                </div>
            </div>
            <div className="p-message p-component p-message-warn p-message-enter-done m-0 p-0">
                <div className="p-message-wrapper">
                    <span className="p-message-icon pi  pi-info-circle"></span>
                    <span className="p-message-summary"></span>
                    <span className="p-message-detail">
                        <p>
                            This is not real data, just something that we are investigating.
                        </p>
                    </span>
                </div>
            </div>
        </>
    );
};
//import { ProgressBar } from 'primereact/progressbar';
