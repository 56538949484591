import { DropdownChangeParams, Dropdown } from 'primereact/dropdown';
import React from 'react';
import { useGetLookupTagQuery } from '../../api/lookup/lookupApi';
import { LookupCategory, LookupSelectionModel } from '../../api/Ophir';
import { getLogger } from 'loglevel';

const log = getLogger('TagDropdown');
log.setLevel('debug');

export interface TagLookupDropdownProp {
    id?: string;
    value?: LookupSelectionModel;
    dataKey?: string;
    tag: LookupCategory;
    disabled?: boolean | undefined;
    onSelected?: ((e: LookupSelectionModel) => void) | undefined;
}

export const TagLookupDropdown = (props: TagLookupDropdownProp) => {
    const { data } = useGetLookupTagQuery(props.tag);

    function onSelected(e: DropdownChangeParams) {
        if (!data || data.length === 0 || !props.onSelected) return;
        const onSelect = props.onSelected;
        data.some((v) => {
            if (v.id === e.value) {
                onSelect(v);
                return true;
            }
            return false;
        });
    }

    log.debug('Dropdown props ', props);
    return (
        <Dropdown
            id={props.id}
            value={props.value?.id}
            onChange={onSelected}
            options={data}
            optionLabel="display"
            optionValue="id"
            dataKey={props.dataKey || props.tag}
            disabled={props.disabled}
            placeholder="Select..."
        />
    );
};
