import { useIsAuthenticated } from '@azure/msal-react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { MenuItem } from 'primereact/menuitem';
import { useEffect, useState } from 'react';
import { useGetSavedSearchesQuery } from './api/user/savedSearchApi';
import { NavigateTo } from './utilities/NavigateTo';

export interface OphirMenuItem extends MenuItem {
    to?: string;
    authenticated?: boolean;
    admin?: boolean;
}

const dashboard: OphirMenuItem = {
    label: 'Dashboard',
    icon: 'pi pi-home',
    to: NavigateTo.dashboard
};

const templates: OphirMenuItem = { label: 'Templates', icon: 'pi pi-list', to: NavigateTo.templates, authenticated: true };

const screener: OphirMenuItem = {
    label: 'Screener',
    icon: 'pi pi-chart-bar',
    to: NavigateTo.screener
};

const savedScreens: OphirMenuItem = {
    label: 'Saved Screens',
    icon: 'pi pi-star',
    to: NavigateTo.savedSearches,
    authenticated: true
};

const management: OphirMenuItem = {
    label: 'Management',
    icon: 'pi pi-briefcase',
    authenticated: true,
    admin: true,
    items: [
        { label: 'Stocks', icon: 'pi pi-th-large', to: '/management/ticker' } as OphirMenuItem,
        { label: 'Saved Search Templates', icon: 'pi pi-qrcode', to: NavigateTo.savedSearchesAdmin } as OphirMenuItem
    ]
};

const defaultMenu: OphirMenuItem[] = [dashboard, templates, screener, savedScreens, management];
export const useMenu = () => {
    const [menu, setMenu] = useState<OphirMenuItem[]>(defaultMenu);
    const isAuthenticated = useIsAuthenticated();
    const { data } = useGetSavedSearchesQuery(isAuthenticated ? undefined : skipToken, { skip: !isAuthenticated });

    useEffect(() => {
        if (data && data.object && data.object.length > 0) {
            savedScreens.items = data.object.map((us) => {
                return { label: us.displayName, to: `/screener/view/${us.id}` };
            });
            savedScreens.items.unshift({
                label: 'View all',
                to: NavigateTo.savedSearches
            } as OphirMenuItem);
            setMenu([...defaultMenu]);
        }
    }, [data]);

    return menu;
};
