import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { SplitButton } from 'primereact/splitbutton';
import React, { useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import { TickerMetaModel, ResponseObjectOfIListOfTickerMetaModel } from '../../../api/Ophir';
import { InputText } from 'primereact/inputtext';
import format from 'date-fns/format/index';
import { Button } from 'primereact/button';

export interface TickersMetaGridProp {
    onSelected: (rowData: TickerMetaModel) => void;
    onShowPrice: (rowData: TickerMetaModel) => void;
    onShowIndicators: (rowData: TickerMetaModel) => void;
    onShowBadges: (rowData: TickerMetaModel) => void;
}

export const TickersMetaGrid = (props: TickersMetaGridProp) => {
    const [globalFilter, setGlobalFilter] = useState('');
    const [offset] = useState(0);
    const [limit] = useState(20);
    const [url, setUrl] = useState('');
    const [gridData, setGridData] = useState<TickerMetaModel[]>([]);
    const { loading } = useFetch(
        `management/ticker?offset=${offset}&limit=${limit}`,
        {
            onNewData: (current: ResponseObjectOfIListOfTickerMetaModel, next: ResponseObjectOfIListOfTickerMetaModel) => {
                if (next.object) {
                    setGridData(next.object);
                }
            },
            perPage: limit
        },
        [url]
    );

    useEffect(() => {
        const newUrl = `management/ticker?offset=${offset}&limit=${limit}`;
        if (url !== newUrl) {
            setUrl(newUrl);
        }
    }, [offset, limit, url]);

    return (
        <DataTable
            value={gridData}
            selectionMode="single"
            dataKey="tickerId"
            paginator
            globalFilter={globalFilter}
            rows={limit}
            rowsPerPageOptions={[20, 40, 80]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tickers"
            emptyMessage="No stocks found"
            loading={loading}
            header={
                <div className="table-header">
                    <div className="flex p-p-3">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText
                                type="search"
                                value={globalFilter}
                                onChange={(e) => setGlobalFilter(e.target.value)}
                                placeholder="Search in the table below"
                            />
                        </span>
                    </div>
                </div>
            }>
            <Column field="ticker" header="Ticker" sortable />
            <Column field="exchange" header="Exchange" sortable />
            <Column field="companyName" header="Company Name" sortable />
            <Column
                body={(rowData) => (
                    <>
                        <SplitButton
                            icon="pi pi-pencil"
                            className="p-button-secondary"
                            label="Edit"
                            model={[
                                {
                                    label: 'View Prices',
                                    command: () => props.onShowPrice(rowData)
                                },
                                {
                                    label: 'View Indicators',
                                    command: () => props.onShowIndicators(rowData)
                                },
                                {
                                    label: 'View Badges',
                                    command: () => props.onShowBadges(rowData)
                                }
                            ]}
                            onClick={() => props.onSelected(rowData)}
                        />
                    </>
                )}
            />
        </DataTable>
    );
};
