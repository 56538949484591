import { Badge } from 'primereact/badge';
import { Divider } from 'primereact/divider';
import React, { useMemo } from 'react';
import { TickerGridDatedModel } from './TickerAdvSearchGrid';
import { TickerChart } from '../TickerChart/TickerChart';
import { Chip } from 'primereact/chip';
import * as badgesData from '../../utilities/BadgesData';

export interface TickerExpandRowProp {
    data: TickerGridDatedModel;
}

export const TickerExpandRow = (props: TickerExpandRowProp) => {
    const badges = useMemo(() => {
        const b = props.data.badges;
        const sector = badgesData.findByKey(badgesData.findByPrefixInArray(badgesData.SectorPrefix, b) || '');
        const industry = badgesData.findByKey(badgesData.findByPrefixInArray(badgesData.IndustryPrefix, b) || '');
        return (
            <div className={'flex-row mt-2'}>
                {sector && <Chip label={'Sector: ' + sector.label} className={'ml-3'} />}
                {industry && <Chip label={'Industry: ' + industry.label} className={'ml-3'} />}
            </div>
        );
    }, [props.data.badges]);

    if (!props.data.tickerId) return <></>;

    return (
        <div className="flex-column col p-0 m-0">
            <Divider align="center">
                <Badge value={props.data.tickerId} />
            </Divider>
            <h4 className="text-center">
                {props.data.companyName} {badges}
            </h4>
            <div>
                <TickerChart tickerId={props.data.tickerId} />
            </div>
        </div>
    );
};
