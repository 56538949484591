import React from 'react';
import { Button } from 'primereact/button';
import { useForm, Controller, FieldError } from 'react-hook-form';
import { SavedSearchAdminModel } from '../../api/Ophir';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import getLogger from '../../utilities/Logger';
import { useIsAdmin } from '../../utilities/useIsAdmin';

let log = getLogger('SavedSearchAdminForm');
log.setLevel('debug');

export interface SavedSearchAdminFormProps {
    selected: SavedSearchAdminModel;
    children?: any;
    isChanging: boolean;

    onCancel: () => void;
    onSubmit: (selected: SavedSearchAdminModel) => void;
}

export const SavedSearchAdminForm = (props: SavedSearchAdminFormProps) => {
    const {
        control,
        formState: { errors },
        handleSubmit
    } = useForm({ defaultValues: props.selected });
    const isAdmin = useIsAdmin();

    const onSubmit = (data: SavedSearchAdminModel) => {
        log.debug('submitting', data); // need to confirm what is the data here and what is in the selected

        if (!data) {
            log.error('blank data received on edit?');
            props.onCancel();
            return;
        }

        props.onSubmit(data);
    };

    const getFormErrorMessage = (name: keyof SavedSearchAdminModel) => {
        let state: FieldError | undefined = errors[name] as FieldError;
        return state && <small className="p-error">{state.message}</small>;
    };
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                {isAdmin && (
                    <>
                        <div className="field  mt-2 mb-2">
                            <span className="p-label">
                                <label htmlFor="templateGroupId" className={classNames({ 'p-error': !!errors.templateGroupId })}>
                                    Template Group
                                </label>
                                <Controller
                                    name="templateGroupId"
                                    control={control}
                                    defaultValue={0}
                                    rules={{
                                        required: false
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            disabled={props.isChanging}
                                            id={field.name}
                                            {...field}
                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                        />
                                    )}
                                />
                            </span>
                            {getFormErrorMessage('templateGroupId')}
                        </div>

                        <div className="field  mt-2 mb-2">
                            <span className="p-label">
                                <label htmlFor="templateFlag" className={classNames({ 'p-error': !!errors.templateFlag })}>
                                    Template Display Flag
                                </label>
                                <Controller
                                    name="templateFlag"
                                    control={control}
                                    defaultValue={0}
                                    rules={{
                                        required: false
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            disabled={props.isChanging}
                                            id={field.name}
                                            {...field}
                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                        />
                                    )}
                                />
                            </span>
                            {getFormErrorMessage('templateFlag')}
                        </div>
                    </>
                )}
                {props.children}
                <div className="flex mt-5">
                    <input type="hidden" name="id" value={props.selected.id} />
                    <Button disabled={props.isChanging} type="button" label="Cancel" className="mr-2 p-button-secondary" onClick={props.onCancel} />
                    <Button disabled={props.isChanging} type="submit" label="Save" className="mr-2" />
                </div>
            </form>
        </>
    );
};
