import React, { useEffect, useRef } from 'react';
import { SearchTemplateList } from '../../components/SearchTemplates/SearchTemplateList';
import { Card } from 'primereact/card';
import { Dashcrumb } from '../../components/Dashcrumb';
import { cScreener } from '../../utilities/Crumbs';
import { Messages } from 'primereact/messages';

const crumbs = [cScreener, { label: 'Choose template' }];

export const SearchTemplatesPage = () => {
    const messages = useRef<Messages>(null);
    useEffect(() => {
        messages.current?.replace({
            severity: 'info',
            closable: false,
            detail: (
                <p>
                    More templates coming later <i className="pi pi-thumbs-up"></i>{' '}
                </p>
            ),
            sticky: true
        });
    });
    return (
        <>
            <Card>
                <Dashcrumb items={crumbs} />
                <h1>Choose a template</h1>
                <hr />
                <p>To get you started we have created some pre-set screens of some popular Technical chart rules.</p>
                <br />
                <div className="grid px-4">
                    <SearchTemplateList selectText="Go" showDescription={true} displayFlag={0} showStats={false} showCreateNew={true} />
                </div>
                <Messages ref={messages} />
            </Card>
        </>
    );
};
