import React, { useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { SearchStatsModel, UserSearchGroupModel, UserSearchModel } from '../../api/Ophir';
import { Skeleton } from 'primereact/skeleton';
import { stringToHslColor } from '../../utilities/Helpers';
import { PrimeIcons } from 'primereact/api';
import { EventType, logEvent } from '../../utilities/Analytics';

export interface SearchTemplateProps {
    search: UserSearchModel;
    selectText: string;
    stats?: SearchStatsModel[];
    isStatsLoading: boolean;
    showDescription: boolean;
    group?: UserSearchGroupModel;
    onSelect: (selected: UserSearchModel) => void;
}

export const SearchTemplateCardSkeleton = () => {
    return (
        <div className="custom-skeleton p-4">
            <Skeleton width="100%" height="150px" />
            <div className="flex mt-3">
                <Skeleton width="4rem" height="2rem" />
            </div>
        </div>
    );
};

export const SearchTemplateCard = (props: SearchTemplateProps) => {
    const stat = useMemo<SearchStatsModel | null>(() => {
        const hash = props.search.searchHash;
        const searchStatsModels = props.stats;
        if (!hash) return null;
        if (!searchStatsModels) return null;
        let result: SearchStatsModel | null = null;
        searchStatsModels.some((s) => {
            if (s.searchHash === hash) {
                result = s;
                return true;
            }
            return false;
        });
        return result;
    }, [props.stats, props.search.searchHash]);

    const [color] = useState(props.search.displayName ? stringToHslColor(props.search.displayName) : 'white');
    return (
        <div className="p-link">
            <div className="card" style={{ borderColor: color, borderStyle: 'solid' }} onClick={() => props.onSelect(props.search)}>
                <div className="p-flex flex">
                    <div className="p-flex-column">
                        <i className={`p-link pi ${props.group?.image} p-mr-2`} style={{ fontSize: '4em' }} />
                    </div>
                    <div className="p-flex-column" style={{ width: '100%' }}>
                        <div className="flex flex-row justify-content-between">
                            <h4>{props.search.displayName} </h4>
                            <div>
                                <Button
                                    icon={PrimeIcons.INFO_CIRCLE}
                                    className="p-button-rounded p-button-info"
                                    tooltip={props.search.description}
                                    tooltipOptions={{
                                        position: 'bottom',
                                        onShow: (e) => {
                                            logEvent('#template-card', 'View help from template - ' + props.search.displayName, EventType.Click);
                                        }
                                    }}
                                    onClick={(e) => e.preventDefault()}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row justify-content-between">
                            <div className="text-light">{props.group?.title}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
