import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { Button } from 'primereact/button';

interface AccessDeniedPageLocationState {
    from: Location;
}

export const AccessDeniedPage = () => {
    const navigate = useNavigate();

    const location = useLocation();

    return (
        <div className="login-body">
            <div className="login-wrapper">
                <div className="login-panel">
                    <Link to={'/'}>
                        <img src="simpulse-mo-xx-mo.png" alt="Share Impulse" style={{ maxWidth: '100%' }} />
                    </Link>

                    <h4 className={'m-5'}>You don't have access to this page</h4>
                    <div className="flex">
                        <Button className="p-button p-button-secondary mr-4" label={'Back'} onClick={() => navigate(-1)} />
                    </div>
                </div>
            </div>
        </div>
    );
};
