import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { useFetch } from "use-http";
import { TickerMetaModel, TickerBadgeModel } from '../../../api/Ophir';

export interface TickerBadgesGridProp {
    header: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined,
    ticker?: TickerMetaModel
}

export const TickerBadgesGrid = (props: TickerBadgesGridProp) => {
    const {
        data,
        loading
     } = useFetch<TickerBadgeModel[]>(`management/ticker/${props?.ticker?.tickerId}/badge`, []);

     return (
        <DataTable
            value={data}
            dataKey="tradeDate"
            paginator
            rows={20}
            rowsPerPageOptions={[20, 40, 80]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} badges"
            emptyMessage="No badges found"
            loading={loading}
            header={props.header}>
            <Column field="tradeDate" header="Date" sortable></Column>
            <Column field="badge" header="Badge" sortable></Column>
        </DataTable>
    );

}