import log from 'loglevel';

export const defaultLevel = 'info';

let getLogger = (loggerName: string) => {
    let logger = log.getLogger(loggerName);
    const originalSetLevel = logger.setLevel;
    logger.setLevel = (level) => originalSetLevel(level, false); // overrides so it is never persisted :) avoiding devs from going crazy
    return logger;
};

var originalFactory = log.methodFactory;

function levelName(l: number | string): string {
    if (typeof l === 'string') {
        switch (l) {
            case 'trace':
                return 'TRC';
            case 'debug':
                return 'DBG';
            case 'info':
                return 'INF';
            case 'warn':
                return 'WRN';
            case 'error':
                return 'ERR';
            case 'silent':
                return 'SIL';
            default:
                return l.toString();
        }
    } else {
        switch (l) {
            case 0:
                return 'TRC';
            case 1:
                return 'DBG';
            case 2:
                return 'INF';
            case 3:
                return 'WRN';
            case 4:
                return 'ERR';
            case 5:
                return 'SIL';
            default:
                return l.toString();
        }
    }
}

log.methodFactory = function (methodName, logLevel, loggerName) {
    var rawMethod = originalFactory(methodName, logLevel, loggerName);
    return function (message) {
        let prefix = '';
        if (typeof loggerName === 'string') {
            prefix = `[${loggerName}:${levelName(methodName)}]`;
        } else {
            prefix = `[${levelName(methodName)}]`;
        }

        let more = [];
        if (arguments.length > 1) {
            for (let ai = 1; ai < arguments.length; ai++) {
                more.push(arguments[ai]);
            }
        }

        if (typeof message === 'string') {
            rawMethod(`${prefix} ${message}`, ...more);
        } else {
            rawMethod(prefix, message, ...more);
        }
    };
};

log.setDefaultLevel(defaultLevel);

export const root = log;
export default getLogger;
