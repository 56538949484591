import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import api, { authApi, cdnApi } from '../api/OphirApi';
import authSlice from '../auth/authStore';
import badgeSelectorSlice from '../components/BadgeSelector/badgeSelectorSlice';

const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        [cdnApi.reducerPath]: cdnApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [authSlice.name]: authSlice.reducer,
        [badgeSelectorSlice.name]: badgeSelectorSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, cdnApi.middleware, authApi.middleware)
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
