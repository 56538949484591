import { TickerSearchRequestModel, UserSearchModel } from '../api/Ophir';

export class NavigateTo {
    static readonly dashboard = '/';
    static readonly login = '/login';
    static readonly templates = '/screener/template';
    static readonly savedSearches = '/screener/list';
    static readonly screener = '/screener/blank';
    static readonly screenerSearch = 'screener/search';

    static readonly _savedSearch = '/screener/view/';

    static readonly savedSearchesAdmin = '/management/templates/';

    static search(search: TickerSearchRequestModel): string {
        return `${NavigateTo.screener}/${toUrl(search)}`;
    }

    static newSearch(userSearch: UserSearchModel): string {
        return `/screener/new/${toUrl({
            displayName: userSearch.displayName,
            content: userSearch.content
        })}`;
    }

    static savedSearch(savedSearch: UserSearchModel | number) {
        const as = savedSearch as UserSearchModel;
        if (as && as.id) {
            return this._savedSearch + as.id;
        }
        if (!Number.isNaN(savedSearch)) {
            return this._savedSearch + savedSearch;
        }
        return this.savedSearches;
    }
}

export const toUrl = (search: any): string => {
    if (typeof search === 'string') {
        return utf8_to_b64(search).replace('+', '-').replace('/', '_').replace(/=+$/, '');
    }
    return utf8_to_b64(JSON.stringify(search)).replace('+', '-').replace('/', '_').replace(/=+$/, '');
};

export const fromUrl = <T>(search?: string): T | undefined => {
    if (!search) return undefined;
    try {
        search = search.replace('-', '+').replace('_', '/');
        while (search.length % 4) search += '=';
        return JSON.parse(b64_to_utf8(search));
    } catch (e) {
        console.log('error', e);
        return undefined;
    }
};

function utf8_to_b64(str: string): string {
    return window.btoa(encodeURIComponent(str));
}

function b64_to_utf8(str: string): string {
    return decodeURIComponent(window.atob(str));
}
