import React from 'react';
import { Card } from 'primereact/card';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { SavedSearchAdminManagement } from '../../components/SavedSearches/SavedSearchAdminManagement';

export const SavedSearchesAdminPage = () => {
    return (
        <>
            <Card>
                <h1>Templates Admin</h1>
                <hr />
                <SavedSearchAdminManagement />
            </Card>
        </>
    );
};
