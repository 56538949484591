import getLogger from './Logger';

export interface AnalyticsEvent {
    href: string;
    title: string;
    type: EventType;
}

interface ClickyEvent {
    href: string;
    title: string;
    type: 'click' | 'pageview';
}

declare var clicky: { log: (href: string, title: string, type: string) => void };

export enum EventType {
    Navigation,
    Click
}

const log = getLogger('Analytics');
log.setLevel('debug');

let events: ClickyEvent[] = [];

function _pushAll() {
    if (!clicky) return;

    while (events.length > 0) {
        const e = events.pop();
        if (e) _logEvent(e);
    }
}

function _uniqueHref(e: ClickyEvent) {
    if (e.type === 'pageview') return e.href;

    let result = e.href;
    if (result.length > 20) result = result.substring(0, 20);
    return result + '#' + encodeURI(e.title.replaceAll(' ', '_'));
}

function _logEvent(e: ClickyEvent) {
    log.info(`${e.type} : '${e.title}' (${e.href})`);
    clicky.log(_uniqueHref(e), e.title, e.type);
}

function _queue(e: ClickyEvent) {
    events.push(e);
}

export function logEvent(event: string | AnalyticsEvent, title?: string, type?: EventType) {
    let href: string = '';
    if (typeof event === 'string') {
        href = event;
    } else {
        title = event.title;
        type = event.type;
        href = event.href;
    }
    const e: ClickyEvent = {
        href,
        title: title || '',
        type: type === EventType.Navigation ? 'pageview' : 'click'
    };

    if (!e.title || !e.href) {
        log.error('Cannot log because there was not enough information to log. ' + e.title + ' / ' + e.href);
        return;
    }

    if (!clicky) {
        _queue(e);
        return;
    }

    if (events.length > 0) _pushAll();

    _logEvent(e);
}
