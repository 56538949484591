import React from 'react';
import { useGetTrendsQuery, useGetTrendsStatsQuery } from '../../api/trends/trendsApi';
import { UserSearchModel } from '../../api/Ophir';
import { useNavigate } from 'react-router-dom';
import { SearchTemplateCard, SearchTemplateCardSkeleton } from './SearchTemplateCard';
import { NavigateTo } from '../../utilities/NavigateTo';
import { SearchTemplateDashCard } from './SearchTemplateDashCard';

export interface SearchTemplateListProps {
    selectText: string;
    showDescription: boolean;
    displayFlag: number;
    showStats: boolean;
    showCreateNew: boolean;
    responsiveClasses?: string;
}

export const SearchTemplateList = (props: SearchTemplateListProps) => {
    const { isLoading: isTrendsLoading, data: trendsData } = useGetTrendsQuery(undefined);
    const { isLoading: isTrendsStatsLoading, data: trendsStatsData } = useGetTrendsStatsQuery(undefined);

    const navigate = useNavigate();

    function goToSearch(trend: UserSearchModel): React.MouseEventHandler<HTMLButtonElement> | undefined {
        if (!trend || !trend.id || !trend.content || !trend.displayName) {
            return;
        }

        navigate(NavigateTo.newSearch(trend));
    }

    const isLoading = () => isTrendsLoading;

    const responsiveClasses = props.responsiveClasses || 'col-12 sm:col-6 md:col-6 xl:col-3';

    let x = 0;
    return (
        <div className={'grid'}>
            {isLoading() && (
                <div className="col-12 sm:col-6 md:col-6" key="trends-loading">
                    <SearchTemplateCardSkeleton />
                </div>
            )}
            {!isLoading() && !trendsData?.object?.length && <>An error occurred. Try again later.</>}
            {!isLoading() &&
                trendsData?.object?.length &&
                trendsData?.object?.map((group) => (
                    <React.Fragment key={`trends-root-${x++}`}>
                        {group.searches?.length &&
                            group.searches.map((trend) => (
                                <React.Fragment key={`trends-group-${x++}`}>
                                    {(props.displayFlag === 0 || trend.admin?.templateFlag === props.displayFlag) && (
                                        <div className={responsiveClasses} key={`trends-col-${x++}`}>
                                            {!props.showStats && (
                                                <SearchTemplateCard
                                                    key={`trends-cardnostat-${x++}`}
                                                    selectText={props.selectText}
                                                    search={trend}
                                                    isStatsLoading={isTrendsStatsLoading}
                                                    stats={trendsStatsData?.object}
                                                    showDescription={props.showDescription}
                                                    onSelect={goToSearch}
                                                    group={group}
                                                />
                                            )}
                                            {props.showStats && (
                                                <SearchTemplateDashCard
                                                    key={`trends-cardstat-${x++}`}
                                                    selectText={props.selectText}
                                                    search={trend}
                                                    isStatsLoading={isTrendsStatsLoading}
                                                    stats={trendsStatsData?.object}
                                                    showDescription={props.showDescription}
                                                    onSelect={goToSearch}
                                                    group={group}
                                                />
                                            )}
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                    </React.Fragment>
                ))}
            {props.showCreateNew && (
                <div className={responsiveClasses} key={`trends-col-${x++}`}>
                    <div className="p-link">
                        <div
                            className="card"
                            style={{ borderColor: 'rgb(52, 181, 111)', borderStyle: 'solid' }}
                            onClick={() => navigate(NavigateTo.screener)}>
                            <div className="p-flex flex">
                                <div className="p-flex-column">
                                    <i className={`p-link pi pi-plus p-mr-2`} style={{ fontSize: '4em' }} />
                                </div>
                                <div className="p-flex-column" style={{ width: '100%' }}>
                                    <div className="flex flex-row justify-content-between">
                                        <h4>Create new screener</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
