import React from 'react';
import { UserSearchModel } from '../../api/Ophir';
import { useNavigate } from 'react-router-dom';
import { NavigateTo} from '../../utilities/NavigateTo';
import { useGetSavedSearchesQuery, useGetSavedSearchStatsQuery } from '../../api/user/savedSearchApi';
import { SavedSearchDashCard, SavedSearchCardSkeleton } from './SavedSearchDashCard';
import { EventType, logEvent } from '../../utilities/Analytics';

export const SavedSearchCardList = () => {
    const { isLoading: isSavedSearchLoading, data: savedSearchData } = useGetSavedSearchesQuery(undefined);
    const { isLoading: isSavedSearchStatsLoading, data: savedSearchStatsData } = useGetSavedSearchStatsQuery(undefined);

    const navigate = useNavigate();

    function goToSearch(trend: UserSearchModel): React.MouseEventHandler<HTMLButtonElement> | undefined {
        if (!trend || !trend.id || !trend.content) {
            return;
        }

        logEvent('#dashSS', 'Selected Saved Screen from Dashboard', EventType.Click);
        navigate(NavigateTo.savedSearch(trend));
    }

    const isLoading = () => isSavedSearchLoading;
    const hasSavedSearches = () => !isSavedSearchLoading && savedSearchData && savedSearchData.object && savedSearchData.object.length > 0;
    const searches = () =>
        // same as above, but this returns an array to always render
        !isSavedSearchLoading && savedSearchData && savedSearchData.object && savedSearchData.object.length > 0 ? savedSearchData.object : [];

    let x = 0;
    return (
        <>
            {isLoading() && (
                <div className="col-12 sm:col-6 md:col-6 lg:col-4" key="trends-loading">
                    <SavedSearchCardSkeleton />
                </div>
            )}
            {!isLoading() && !hasSavedSearches() && <>An error occurred. Try again later.</>}
            {!isLoading() &&
                searches().map((trend) => (
                    <div className="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-4" key={`trends-col-${x++}`}>
                        <SavedSearchDashCard
                            selectText="View"
                            search={trend}
                            isStatsLoading={isSavedSearchStatsLoading}
                            stats={savedSearchStatsData?.object}
                            onSelect={goToSearch}
                        />
                    </div>
                ))}
        </>
    );
};
