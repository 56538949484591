import React, { useEffect, useRef } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { SignInButton } from './auth/SignInButton';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import AppMenu from './AppMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from './assets/images/logo/logo-light.png';
import { NavigateTo } from './utilities/NavigateTo';
import { EventType } from './utilities/Analytics';

const AppTopbar = (props: any) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const location = useLocation();
    const navigate = useNavigate();
    const searchPanelDesktop: any = useRef(null);
    const searchPanelMobile: any = useRef(null);

    useEffect(() => {
        if (props.searchActive) {
            if (window.innerWidth >= 576) searchPanelDesktop.current.focus();
            else searchPanelMobile.current.focus();
        }
    }, [props.searchActive]);

    const onInputKeydown = (event: any) => {
        const key = event.which;

        //escape, tab and enter
        if (key === 27 || key === 9 || key === 13) {
            if (props.searchActive) props.onTopbarSearchToggle();
        }
    };

    const handleLogout = () => {
        console.log('logging out...');
        instance.logoutPopup({
            postLogoutRedirectUri: '/',
            mainWindowRedirectUri: '/'
        });

        // redirect:
        // instance.logoutRedirect({
        //     postLogoutRedirectUri: "/",
        // });
    };

    return (
        <div className="layout-topbar">
            <div className="layout-topbar-wrapper">
                <div className="layout-topbar-left">
                    <button tabIndex={0} className="menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>
                    <button tabIndex={0} id="logo-link" onClick={() => navigate('/')} className="layout-topbar-logo p-link">
                        <img src={logo} alt="Share Impulse" />
                    </button>
                </div>

                <AppMenu
                    menuMode={props.menuMode}
                    sidebarActive={props.sidebarActive}
                    sidebarStatic={props.sidebarStatic}
                    model={props.menu}
                    menuActive={props.menuActive}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                    onMobileMenuActive={props.onMobileMenuActive}
                    onMenuItemClick={props.onMenuItemClick}
                    onSidebarMouseOver={props.onSidebarMouseOver}
                    onSidebarMouseLeave={props.onSidebarMouseLeave}
                    onToggleMenu={props.onToggleMenu}
                    resetActiveIndex={props.resetActiveIndex}
                    onMenuClick={props.onMenuClick}
                />

                <div className="layout-topbar-right">
                    <ul className="layout-topbar-actions">
                        <li className={classNames('topbar-item search-item', { 'active-topmenuitem': props.searchActive })}>
                            {/* <button className="p-link" tabIndex={0} style={{ cursor: 'pointer' }} onClick={props.onTopbarSearchToggle}>
                                <i className="topbar-icon pi pi-search"></i>
                            </button> */}

                            <div className="search-input-wrapper" onClick={props.onTopbarSearchClick}>
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search"></i>
                                    <InputText ref={searchPanelDesktop} type="text" placeholder="Search..." onKeyDown={onInputKeydown} />
                                </span>
                            </div>

                            <ul className="fadeInDown">
                                <div className="search-input-wrapper p-fluid" style={{ width: '100%' }} onClick={props.onTopbarSearchClick}>
                                    <span className="p-input-icon-left">
                                        <i className="pi pi-search"></i>
                                        <InputText ref={searchPanelMobile} type="text" placeholder="Search..." onKeyDown={onInputKeydown} />
                                    </span>
                                </div>
                            </ul>
                        </li>

                        <AuthenticatedTemplate>
                            <li className={classNames('topbar-item user-profile', { 'active-topmenuitem fadeInDown': props.topbarUserMenuActive })}>
                                <button className="p-link" style={{ cursor: 'pointer' }} onClick={props.onTopbarUserMenuClick}>
                                    <i className="p-button-icon p-c pi pi-user"></i>
                                    &nbsp;
                                    {account?.name}
                                </button>
                                <ul className="fadeInDown">
                                    <li>
                                        <button className="p-link">
                                            <span>Profile</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="p-link" onClick={handleLogout}>
                                            Logout
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <li>
                                <SignInButton
                                    analytics={{
                                        href: location.pathname,
                                        title: 'Login via the top bar',
                                        type: EventType.Click
                                    }}
                                />
                            </li>
                        </UnauthenticatedTemplate>
                    </ul>
                    {/* 
                    <button tabIndex={0} style={{ cursor: 'pointer' }} className="layout-rightpanel-button p-link" onClick={props.onRightPanelButtonClick}>
                        <i className="pi pi-arrow-left"></i>
                    </button> */}
                </div>
            </div>
        </div>
    );
};

export default AppTopbar;
