import { useMsal } from '@azure/msal-react';
import { isAdminAccount } from './UserInfoUtils';
import { useMemo } from 'react';

export function useIsAdmin() {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    return useMemo(() => {
        return isAdminAccount(account);
    }, [account]);
}
