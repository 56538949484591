// Big dump - reconsider better named helper files for each of the helpers added here -i.e. dateutils

export const isEmpty = (object: any): boolean => {
    for (var _i in object) {
        return false;
    }
    return true;
};

export const arraysEqual = (a: [], b: []) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (!b.some((bv) => bv === a[i])) return false;
    }
    for (let i = 0; i < b.length; ++i) {
        if (!a.some((av) => av === b[i])) return false;
    }
    return true;
};

const saturation = 70;
const lightness = 60;

export function stringToHslColor(str: string) {
    const s = saturation;
    const l = lightness;
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}
