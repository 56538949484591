import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';

import { contentRoutes } from './App.route';
import { useMenu } from './App.menu';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

const App = () => {
    const menuMode = 'horizontal';
    const topbarScheme = 'dark';
    const menuScheme = 'dark';
    const ripple = false;
    const inputStyle = 'filled';

    const [resetActiveIndex, setResetActiveIndex] = useState(null);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    //const [menuMode, setMenuMode] = useState('sidebar');
    //const [inputStyle, setInputStyle] = useState('filled');
    //const [ripple, setRipple] = useState(false);

    //const [rightPanelActive, setRightPanelActive] = useState(false);
    //const [colorScheme, setColorScheme] = useState('light')
    //const [topbarScheme, setTopbarScheme] = useState('light')
    //const [menuScheme, setMenuScheme] = useState('light')
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);

    const menu = useMenu();

    let menuClick;
    //let rightPanelClick;
    let searchClick;
    let topbarUserMenuClick;

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        } else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]);

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setResetActiveIndex(true);
            hideOverlayMenu();
        }
        if (!event.item.items && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }
    };

    const onMenuClick = (event) => {
        if (menuActive && event.target.className === 'layout-menu-container') {
            setResetActiveIndex(true);
            setMenuActive(false);
        }
        menuClick = true;
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(
                new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
                ' '
            );
        }
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        //setRightPanelActive(false);

        if (isMobile()) {
            setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
            if (staticMenuMobileActive) {
                blockBodyScroll();
            } else {
                unblockBodyScroll();
            }
        }
        event.preventDefault();
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const hideOverlayMenu = () => {
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    // const onRightPanelClick = () => {
    //     rightPanelClick = true;
    // };

    // const onRightPanelButtonClick = () => {
    //     setRightPanelActive((prevState) => !prevState);
    //     rightPanelClick = true;
    // };

    const onTopbarSearchToggle = () => {
        setSearchActive((prevState) => !prevState);
        searchClick = true;
    };

    const onTopbarSearchClick = () => {
        searchClick = true;
    };

    const onTopbarUserMenuClick = () => {
        setTopbarUserMenuActive((prevState) => !prevState);
        topbarUserMenuClick = true;
    };

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            setSearchActive(false);
            searchClick = false;
        }

        if (!topbarUserMenuClick && topbarUserMenuActive) {
            setTopbarUserMenuActive(false);
            topbarUserMenuClick = false;
        }

        // if (!rightPanelClick && rightPanelActive) {
        //     setRightPanelActive(false);
        // }

        if (!menuClick) {
            if (isSlim() || isHorizontal()) {
                setResetActiveIndex(true);
                setMenuActive(false);
            }

            if (staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        searchClick = false;
        topbarUserMenuClick = false;
        menuClick = false;
    };

    const onSidebarMouseOver = () => {
        setSidebarActive(!isMobile());
    };

    const onSidebarMouseLeave = () => {
        setSidebarActive(false);
    };

    const onToggleMenu = (event) => {
        menuClick = true;
        setSidebarStatic((prevState) => !prevState);

        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
    };

    const layoutClassName = classNames(
        'layout-wrapper',
        {
            'layout-sidebar': menuMode === 'sidebar',
            'layout-static': menuMode === 'sidebar' && sidebarStatic,
            'layout-horizontal': menuMode === 'horizontal',
            //'layout-rightpanel-active': rightPanelActive,
            'layout-slim': menuMode === 'slim',
            'layout-mobile-active': staticMenuMobileActive,
            'p-input-filled': inputStyle === 'filled',
            'p-ripple-disabled': !ripple
        },
        'layout-menu-' + menuScheme + ' layout-topbar-' + topbarScheme
    );

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <AppTopbar
                topbarScheme={topbarScheme}
                onRightPanelButtonClick={false} //{onRightPanelButtonClick}
                searchActive={searchActive}
                onTopbarSearchToggle={onTopbarSearchToggle}
                onTopbarSearchClick={onTopbarSearchClick}
                topbarUserMenuActive={topbarUserMenuActive}
                onTopbarUserMenuClick={onTopbarUserMenuClick}
                menu={menu}
                menuActive={menuActive}
                onRootMenuItemClick={onRootMenuItemClick}
                mobileMenuActive={staticMenuMobileActive}
                onMenuItemClick={onMenuItemClick}
                menuMode={menuMode}
                sidebarStatic={sidebarStatic}
                sidebarActive={sidebarActive}
                onSidebarMouseOver={onSidebarMouseOver}
                onSidebarMouseLeave={onSidebarMouseLeave}
                onToggleMenu={onToggleMenu}
                onMenuButtonClick={onMenuButtonClick}
                resetActiveIndex={resetActiveIndex}
                onMenuClick={onMenuClick}
            />
            <div className="layout-main px-0 sm:px-1 md:px-8 lg:px-12">
                <div className="layout-content">{contentRoutes}</div>

                <AppFooter />
            </div>

            <div className="layout-mask modal-in"></div>
        </div>
    );
};

export default App;
