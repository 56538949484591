import React from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { SignInButton } from '../../auth/SignInButton';
import { useIsAuthenticated } from '@azure/msal-react';
import { Button } from 'primereact/button';
import { EventType, logEvent } from '../../utilities/Analytics';
import { NavigateTo } from '../../utilities/NavigateTo';

interface LoginPageLocationState {
    from: Location;
}

export const LoginRequiredPage = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const auth = useIsAuthenticated();

    const state = location.state as LoginPageLocationState;
    const { from } = state.hasOwnProperty('from') ? (state as LoginPageLocationState) : { from: { pathname: '/' } };

    if (from && from.pathname && auth) {
        return <Navigate to={from} />;
    }

    function logClick(value: string) {
        logEvent(NavigateTo.login, value, EventType.Click);
    }

    return (
        <div className="login-body">
            <div className="login-wrapper">
                <div className="login-panel">
                    <Link to={'/'}>
                        <img src="simpulse-mo-xx-mo.png" alt="Share Impulse" style={{ maxWidth: '100%' }} />
                    </Link>

                    <h4 className={'m-5'}>You need to login or create an account to access this page.</h4>
                    <div className="flex">
                        <Button
                            className="p-button p-button-secondary mr-4"
                            label={'Back'}
                            onClick={() => {
                                logClick('Went BACK from login - No access to "' + from?.pathname + '"');
                                navigate(-1);
                            }}
                        />
                        <SignInButton
                            className="p-button p-button-primary"
                            analytics={{
                                href: NavigateTo.login,
                                title: 'Login to view "' + from?.pathname + '"',
                                type: EventType.Click
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
