import React, { useEffect, useRef, useState } from 'react';
import { TickerSearchRequestModel, UserSearchModel } from '../../api/Ophir';
import { Dialog } from 'primereact/dialog';
import { SavedSearchToolbar } from './SavedSearchToolbar';
import { SavedSearchForm } from './SavedSearchForm';
import { useCreateSavedSearchMutation, useDeleteSavedSearchMutation, useEditSavedSearchMutation } from '../../api/user/savedSearchApi';
import { Messages } from 'primereact/messages';
import { Toast } from 'primereact/toast';
import { SignInButton } from '../../auth/SignInButton';
import { useIsAuthenticated } from '@azure/msal-react';

import getLogger from '../../utilities/Logger';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { NavigateTo } from '../../utilities/NavigateTo';
import { EventType, logEvent } from '../../utilities/Analytics';
import { useNavigate } from 'react-router-dom';

let log = getLogger('SavedSearchTitle');
log.setLevel('debug');

export interface SavedSearchTitleManagerProps {
    selected?: UserSearchModel;
    searchRequest?: TickerSearchRequestModel;
}

export const SavedSearchTitleManager = ({ selected, searchRequest }: SavedSearchTitleManagerProps) => {
    const navigate = useNavigate();
    const formMessage = useRef<Messages>(null);
    const toast = useRef<Toast>(null);
    const [editDialog, setEditDialog] = useState<boolean>(false);
    const [search, setSearch] = useState<UserSearchModel>(selected || {});
    const [isChanging, setChanging] = useState<boolean>(false);
    const [isLoginRequired, setLoginRequired] = useState<boolean>(false);
    const isAuthenticated = useIsAuthenticated();

    const [createPost, { isLoading: isCreating }] = useCreateSavedSearchMutation();
    const [editPost, { isLoading: isEditing }] = useEditSavedSearchMutation();
    const [deletePost, { isLoading: isDeleting }] = useDeleteSavedSearchMutation();

    useEffect(() => {
        setChanging(isEditing || isCreating || isDeleting);
    }, [isEditing, isCreating, isDeleting]);

    useEffect(() => {
        // this is where we do the want to save ebfore changing the actual values
        if (selected) {
            log.debug('received new selected', selected);
            setSearch(selected);
        }
    }, [selected]);

    function toolbarCreate() {
        if (!isAuthenticated) {
            logClick('Asking user to Login to Save Screener');
            setLoginRequired(true);
            return;
        }

        logClick('Showing dialog to Create new Screener ');

        setSearch({
            displayName: selected?.displayName,
            description: selected?.description
        });
        setEditDialog(true);
    }

    function toolbarEdit(userSearch: UserSearchModel) {
        logClick('Editing Saved Screener - ' + userSearch.displayName);
        log.debug('sending to edit', userSearch);
        setSearch({
            ...userSearch
        });
        setEditDialog(true);
    }

    async function toolbarDelete(userSearch: UserSearchModel) {
        logClick('Delete Saved Screener - ' + userSearch.displayName);
        let deleteResult = await deletePost(userSearch).unwrap();
        log.debug(deleteResult);
        toast?.current?.show([
            {
                severity: 'warn',
                summary: 'Screen Deleted'
            }
        ]);
        navigate(NavigateTo.screener);
    }

    async function completeSavedSearch(userSearch: UserSearchModel) {
        log.debug('completing', userSearch);
        let error: boolean;
        let saveSearch: UserSearchModel = {
            ...userSearch,
            content: searchRequest
        };
        if (!userSearch.id) {
            saveSearch = await createPost(saveSearch).unwrap();
            error = !!!saveSearch.id;
        } else {
            saveSearch = await editPost(saveSearch).unwrap();
            error = !!!saveSearch.id;
        }

        if (error) {
            formMessage?.current?.show([
                {
                    severity: 'error',
                    summary: 'Failed to update',
                    detail: 'We were unable to save your screener. Please try again or contact us for support.',
                    sticky: true
                }
            ]);
            return;
        }

        setEditDialog(false);
        setSearch(saveSearch);
        toast?.current?.show([
            {
                severity: 'success',
                summary: 'Screen Saved'
            }
        ]);
    }

    async function bottomSave() {
        if (selected && selected.id) {
            await toolbarSave(selected);
        } else {
            toolbarCreate();
        }
    }

    async function toolbarSave(userSearch: UserSearchModel) {
        logClick('Save Screener - ' + userSearch.displayName);
        log.debug('toolbar save', userSearch);
        if (userSearch.id) {
            completeSavedSearch({
                ...userSearch,
                content: searchRequest
            });
        }
    }

    function logClick(value: string) {
        logEvent(NavigateTo._savedSearch, value, EventType.Click);
    }

    return (
        <div className="flex align-items-center">
            {search.displayName && <span>{search.displayName}</span>}
            {!search.displayName && <span>Screener</span>}
            <div className="ml-5">
                <SavedSearchToolbar
                    selected={search}
                    visible={true}
                    onSave={toolbarSave}
                    onEdit={toolbarEdit}
                    onCreate={toolbarCreate}
                    onDelete={toolbarDelete}
                />
            </div>
            <Dialog
                header="Save Screener"
                visible={editDialog}
                onHide={() => setEditDialog(false)}
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '50vw' }}>
                <SavedSearchForm
                    selected={search}
                    isChanging={isChanging}
                    onSubmit={async (e) => await completeSavedSearch(e)}
                    onCancel={() => setEditDialog(false)}>
                    <Messages ref={formMessage} />
                </SavedSearchForm>
            </Dialog>
            <Dialog
                header="Create an account or login"
                visible={isLoginRequired}
                onHide={() => setLoginRequired(false)}
                footer={
                    <SignInButton
                        onClick={() => setLoginRequired(false)}
                        analytics={{
                            href: NavigateTo._savedSearch,
                            title: 'Login from dialog to save screener',
                            type: EventType.Click
                        }}
                    />
                }>
                <p>You can save your screener setting as soon as you create an account.</p>
            </Dialog>
            <Toast ref={toast} />
            <div
                style={{
                    left: 0,
                    bottom: '20px',
                    position: 'fixed',
                    zIndex: 5,
                    width: '100%',
                    textAlign: 'center',
                    pointerEvents: 'none'
                }}>
                <span
                    style={{
                        zIndex: 5,
                        pointerEvents: 'visible'
                    }}>
                    <Button
                        icon={classNames('pi', {
                            'pi-save': selected && selected.id,
                            'pi-star': !selected || !selected.id
                        })}
                        label="Save"
                        onClick={() => bottomSave()}
                    />
                </span>
            </div>
        </div>
    );
};
